import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/solutions/cv/backgnd.webp";
import i1 from "../../assets/imgs/solutions/cv/ik1.webp";
import i2 from "../../assets/imgs/solutions/cv/ik2.webp";
import i3 from "../../assets/imgs/solutions/cv/ik3.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

let aosEffect = "zoom-up";

const head = () => {
  return (
    <>
      <section
        style={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${bannerback})`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <MyNavigationBar data-aos={aosEffect} page="Solutions" />
        <Col data-aos={aosEffect}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h3 className="headlineUl">
            <h1 className="headlineH1"></h1>
          </h3>
        </Col>
      </section>
    </>
  );
};

const body = () => {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <Container>
        <Row>
          <HeadLine_colored text="Solutions for Object, Movement, and Pattern Recognition" />
          <ContentText_white
            text="infinity offers a whole gamut of computer vision development
            services around image retrieval and object detection solutions,
            featuring high processing speed without sacrificing performance by:"
          />
        </Row>
        <Container data-aos={aosEffect}>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>
                Building powerful CV systems that perform image classification,
                localization and pixel-level segmentation.
              </li>
              <li>
                Applying CV, ML and DL methods for face analysis, gesture and
                movement recognition, and machine vision.
              </li>
              <li>
                Developing task-oriented algorithms for background separation,
                digital filtering, noise suppression and shadow removal to
                achieve a higher degree of image accuracy.
              </li>
              <li>Designing features and classifiers for pattern matching.</li>
            </ul>
          </Col>
        </Container>
      </Container>
      <br></br>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "35px",
        }}
      >
        <img
          style={{ borderRadius: "35px" }}
          src={i1}
          alt="iot img"
          width="70%"
          height="50%"
          data-aos={aosEffect}
        ></img>
      </div>
      <br></br>
      <br></br>
      <Right_1
        head="Object Tracking"
        content="OMulti-object tracking (MOT) software in a live video stream,
      CNN-based object detection and target tracking systems, and CV
      software for diverse camera types.."
        imgsrc={i2}
      />
      <br></br>
      <br></br>

      <Left_1
        head="Video Analysis"
        content="Disaster management and security solutions, traffic-monitoring
        software, video and image analysis apps for IP cameras, video
        servers and PC-based platforms."
        imgsrc={i3}
      />
      <br></br>
      <br></br>
      <Container>
        <Row>
          <HeadLine_colored text="Solutions for object, movement, pattern recognition" />
          <ContentText_white
            text="Multi-factor authentication products, AI-enhanced CCTV monitoring
            solutions, and biometric digital pattern matching software."
          />
        </Row>
      </Container>
      <br></br>
      <br></br>
    </>
  );
};
const ComputerVision = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      <MyNavigationBar data-aos={aosEffect} page="Solutions" />
      <Bnr
        imgsrc={bannerback}
        text="Computer Vision Solutions"
        subtext="Our ML expertise is the foundation of our Computer Vision service
        offering. We will find the best way to address your challenges by
        crafting tailored recognition processes and multi-parameter analysis
        solutions no matter what project you are envisaging: an industrial
        automation visual inspection (AVI) platform, an advanced CCTV system
        or image-based analytics tool implementation."
      ></Bnr>

      {body()}
      <MyFooter />
    </section>
  );
};

export default ComputerVision;
