import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/solutions/aiml/backgnd.webp";
import i1 from "../../assets/imgs/solutions/aiml/ik1.webp";
import i2 from "../../assets/imgs/solutions/aiml/ik2.webp";
import i3 from "../../assets/imgs/solutions/aiml/ik3.webp";
import i4 from "../../assets/imgs/solutions/aiml/ik4.webp";
import i5 from "../../assets/imgs/solutions/aiml/ik5.webp";
import i6 from "../../assets/imgs/solutions/aiml/ik6.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

let aosEffect = "zoom-up";

const body = () => {
  var screenWidth = window.innerWidth;
  let h1b = screenWidth > 600 ? 0.7 : 0;
  return (
    <>
      <br></br>
      <br></br>
      <Right_1
        head="Computer Vision"
        content="From sport activity tracking to vehicle detection and intelligent
      surveillance, our experts create smart algorithms capable of quick
      real-world image processing and visual data analysis. Using
      advanced computer vision technologies, we design systems for
      visual sensing, object labeling, intelligent image and video
      analysis. Our solutions can be used for object, face, and gesture
      recognition, machine vision, autonomous vehicles, and robots."
        imgsrc={i1}
      />
      <br></br> <br></br>
      <Left_1
        head="Natural Language Processing (NLP)"
        content="Our NLP solutions can be leveraged in smart search systems,
        customer services, autonomous cars, and healthcare. They can
        optimize any business workflow. We develop systems for
        statistical machine translation, optical text recognition, text
        processing, programmatic intent analysis, and analysis of
        real-world speech pattern"
        imgsrc={i2}
      />
      <br></br> <br></br>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "35px",
        }}
      >
        <img
          style={{ borderRadius: "35px", width: "100%" }}
          src={i3}
          alt="iot img"
          data-aos={aosEffect}
        ></img>
      </div>
      <br></br> <br></br>
      <Container>
        <Row>
          <HeadLine_colored text="Conversational AI/Chatbots" />
          <ContentText_white
            text="
              infinity is a machine learning development company with a knack
              for AI chatbots. We build them for popular messengers and
              custom-built apps. Our chatbot solutions use AI for deep analysis
              of conversational patterns. They decode natural language and offer
              responses based on the context. They can also track user feedback
              and provide valuable insights.
            "
          />
        </Row>
      </Container>
      <br></br> <br></br>
      <Left_1
        head="Voice and Speech Recognition"
        content="infinity tech ltd helps companies improve communications and
      translate them into a digital format that is easier to manage and
      search. We build AI voice and speech recognition systems for
      customer services, in-car systems, and healthcare and legal
      institutions, just to name a few.We specialize in automatic
      speech-to-text (or command) conversion solutions and the
      integration of business apps with voice recognition services like
      Alexa, Google Assistant, Siri, and Cortana."
        imgsrc={i4}
      />
      <Right_1
        head="Business Intelligence"
        content="We design business intelligence and machine learning solutions,
     data management and modeling to automate your decision-making and
     help your company’s stakeholders foresee business risks and ensure
     security.Our ML/AI experts build data warehouses, ETL systems,
     advanced data visualization, and reporting solutions."
        imgsrc={i5}
      />
      <br></br>
      <br></br> <br></br>
      <br></br> <br></br>
      <Left_1
        head=" Large Language Model Applications"
        content="  We know that large language models (LLMs), such as ChatGPT, are
      effective at solving general problems. But businesses need
      tailored solutions to apply LLMs to specific tasks. LLM
      model-powered apps can be the right fit.At infinity tech ltd, we
      fine-tune the selected LLM and train it on task-specific datasets.
      Then, we integrate this functionality into existing applications.
      We also build LLM apps from scratch."
        imgsrc={i6}
      />
    </>
  );
};

const AiML = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      <MyNavigationBar data-aos={aosEffect} page="Solutions" />
      <Bnr
        imgsrc={bannerback}
        text="AI & Machine Learning Development Services"
        subtext="   Do you want to speed up your journey on the path to digital
        transformation? Consider enhancing your business with machine
        learning development. At infinity tech ltd, we know how to unlock
        the power of computer vision, NLP, and object and voice recognition
        for your company."
      ></Bnr>

      {body()}
      <MyFooter />
    </section>
  );
};

export default AiML;
