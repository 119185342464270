
import { Container, Row, Col } from "react-bootstrap";

import llogo from '../assets/imgs/logoWhite.svg'; // Make sure the path to the image is correct
import facebookIKN from '../assets/imgs/icons/facebook.png';
import ytubeIKN from '../assets/imgs/icons/youtube.png';
import instaIcon from '../assets/imgs/icons/instagram.png';
import LnkdIcon from '../assets/imgs/icons/linkedin.png';
import CallIcon from '../assets/imgs/icons/call.png';
import locationIcon from '../assets/imgs/icons/location.png';




export const MyFooter = () => {
    return (
        <section className='footer'>
            <Container >

                <img className='footerLogo' src={llogo} alt="aaa"></img>

                <Row >
                    <Col >
                        <a className='footerh1' href='/#/services'>Services</a>

                        <br></br><br></br><br></br>
                        <Col>
                            <a className='footerh4' href='/#/services/customsw/'>Custom software Development</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/services/embedded/'>Embedded System Development</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/services/hardware/'>Hardware Design</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/services/mobile/'>Mobile Applications</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/services/desktop/'>Desktop Applications</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/services/fullsatck/'>full-stack Web Development</a>
                        </Col>

                    </Col>
                    <Col >
                        <a className='footerh1' href='/#/solutions'>Solutions</a>

                        <br></br><br></br><br></br>
                        <Col>
                            <a className='footerh4' href='/#/solutions/iot'>IOT</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/solutions/aiml'>AI/Machine Learning</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/solutions/cv'>Computer Vision</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/solutions/automation'>Industerial Automation and Robotics</a>
                        </Col>
                        <br></br>


                    </Col>
                    <Col >
                        <a className='footerh1' href='/#/industries'>Industries</a>

                        <br></br><br></br><br></br>
                        <Col>
                            <a className='footerh4' href='/#/industries/celectronics'>consumer electronics</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/industries/medical'>Health care</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/industries/automotive'>automotive</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='/#/industries/military'>military</a>
                        </Col>
                        <br></br>


                    </Col>
                    {/* <Col >
                        <a className='footerh1' href='/#/about'>about</a>
                    </Col>
                    <Col >
                        <a className='footerh1' href='/#/portofolio'>portfolio</a>

                        {/* <br></br><br></br><br></br>
                        <Col>
                            <a className='footerh4' href='#'>example1</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='#'>example2</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='#'>example3</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='#'>example4</a>
                        </Col>
                        <br></br>
                        <Col>
                            <a className='footerh4' href='#'>example5</a>
                        </Col> */}

                    {/*</Col> */}
                    <Col >
                        <a className='footerh1' href='/#/contactus'>ContactUS</a>

                        <br></br><br></br><br></br>
                        <div style={{ display: "inline-flex" }}>
                            <img className="footerICON" src={locationIcon} alt="aaa" ></img>
                            <a className='footerh4' href='https://www.google.com.eg/maps/search/6+Al+Ashqr+St,+El+Gomhoriah+St,++Mansoura,+Egypt.+(Zip:+35511)/@31.0453499,31.3636015,21z?entry=ttu'>Head office:<br></br>37 Sekket ElSwis St, El-Wailly, Cairo , EG <br></br>Zip  Code: 11887</a>
                        </div>
                        <br></br><br></br>


                        <div style={{ display: "inline-flex" }}>
                            <img className="footerICON" src={locationIcon} alt="aaa"></img>
                            <a className='footerh4' href='https://www.google.com.eg/maps/search/3053B+Mahdy+Arafah+St,+Mostafa+El+Nahass+St,+Nasr+City+(Zip:+11765)/@30.0326101,31.4557353,12z/data=!3m1!4b1?entry=ttu'>UAE Branch:<br></br>201 Al-Maktoum Road, Fujiarah, United Arab Emirates</a>
                        </div>
                        <br></br><br></br>


                        <div style={{ display: "inline-flex" }}>
                            <img className="footerICON" src={CallIcon} alt="aaa"></img>
                            <a className='footerh4' href='/#/home'>Phone: <br></br>(+971)503693357<br></br> (+20)1060449214 <br></br>(+20)1025381934</a>
                        </div>
                        <br></br><br></br>


                        <div style={{ display: "inline-flex" }}>
                            <img className="footerICON" src={locationIcon} alt="aaa"></img>
                            <a className='footerh4' href='https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=m.maher@infinitytech.ltd'>Email:<br></br> info@infinitytech.ltd <br></br>m.maher@infinitytech.ltd</a>
                        </div>
                        <br></br><br></br>

                        <div style={{ display: "inline-flex" }}>
                            <a href="https://www.facebook.com/infinitytech100">
                                <img style={{ marginLeft: "5px" }} src={facebookIKN} width={50} height={50} alt="aaa"></img>
                            </a>
                            <a href="https://www.youtube.com/user/infinitytech100?fbclid=IwAR0eHuuGhFcC1mih3GRpIn_ZYVh8VRzob2St4Rupx3yuMdcc_86U32QPvOI">
                                <img style={{ marginLeft: "5px" }} src={ytubeIKN} width={50} height={50} alt="aaa"></img>
                            </a>
                            <a href="https://www.linkedin.com/company/infinitytech100/">
                                <img style={{ marginLeft: "5px" }} src={LnkdIcon} width={50} height={50} alt="aaa"></img>
                            </a>
                            <a href="https://www.instagram.com/inf_initytech/">
                                <img style={{ marginLeft: "5px" }} src={instaIcon} width={50} height={50} alt="aaa"></img>
                            </a>
                        </div>

                    </Col>
                </Row>
                <br></br><br></br><br></br>
                <Row>
                    <h1 style={{ fontSize: "15px", textAlign: "center", color: "#FFF", fontWeight: 200 }}>© Copyright 2013 - 2023 Infinity Tech Ltd.- All rights reserved .</h1>
                </Row>



            </Container>
        </section>
    )
}
