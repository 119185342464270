import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { MyNavigationBar } from '../../components/MyNavbar';
import AOS from 'aos';
import 'aos/dist/aos.css';

import cswIcon from '../../assets/imgs/services/icons/custom.png'
import embeddIcon from '../../assets/imgs/services/icons/cpu.png'
import HwIcon from '../../assets/imgs/services/icons/electronics.png'
import DesktpIcon from '../../assets/imgs/services/icons/software.png'
import MobileIcon from '../../assets/imgs/services/icons/mobile.png'
import FullstkIcon from '../../assets/imgs/services/icons/webApps.png'

let aosEffect="zoom-out-up";
  
const ServicesOverview = () => {
    const  nav = useNavigate();
    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: "ease-in-sine",
            delay: 50,
        });

        const interval = setInterval(() => {
            AOS.refresh();
        }, 100);

        return () => clearInterval(interval);

    }, []);

    const goto_fullstack = () => {
        window.scrollTo(0, 0);
        nav("/services/fullsatck/#")
      };
      const goto_mobile = () => {
        window.scrollTo(0, 0);
        nav("/services/mobile/#")
      };
      const goto_desktop = () => {
        window.scrollTo(0, 0);
        nav("/services/desktop/#")
      };
      const goto_hardware = () => {
        window.scrollTo(0, 0);
        nav("/services/hardware/#")
      };
      const goto_customsw = () => {
        window.scrollTo(0, 0);
        nav("/services/customsw/#")
      };
      const goto_embedded = () => {
        window.scrollTo(0, 0);
        nav("/services/embedded/#")
      };

    return (
        <section className="servicesPage" data-aos={aosEffect}>
            <MyNavigationBar page='Services'/>
            <br></br><br></br><br></br>
            <h1 className="serviceHeader">our services</h1>
            <div className="serviceGrid">

                <div className="serviceCard"  data-aos={aosEffect} onClick={goto_customsw}>
                    <div className='serviceCardLogo' >
                        <img width={100} height={100} src={cswIcon} alt="img"/>
                    </div>
                    <h3>custom software development</h3>
                    <p> involves creating tailored applications or programs to meet specific business needs, enhancing efficiency and functionality. </p>
                </div>

                <div className="serviceCard"  data-aos={aosEffect} onClick={goto_embedded}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={embeddIcon} alt="img"/>
                    </div>
                    <h3>Embedded System Development</h3>
                    <p>focuses on designing and programming specialized software that operates within embedded systems, such as microcontrollers or IoT devices, to enable precise and dedicated functionality.</p>
                </div>

                <div className="serviceCard"  data-aos={aosEffect} onClick={goto_hardware}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={HwIcon} alt="img"/>
                    </div>
                    <h3>Hardware Design</h3>
                    <p> encompasses the intricate process of conceptualizing, planning, and creating the physical components and circuits of electronic devices, ensuring their functionality, reliability, and efficiency.</p>
                </div>

                <div className="serviceCard"  data-aos={aosEffect} onClick={goto_desktop}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={DesktpIcon} alt="img"/>
                    </div>
                    <h3>Desktop App Development</h3>
                    <p>software programs designed to run on personal computers or workstations, offering a user-friendly interface and robust functionality for tasks ranging</p>
                </div>


                <div className="serviceCard"  data-aos={aosEffect} onClick={goto_mobile}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={MobileIcon} alt="img"/>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>software designed for smartphones and tablets, offering diverse functionalities that cater to communication, entertainment, productivity, and information retrieval on-the-go</p>
                </div>

                <div  className="serviceCard"  data-aos={aosEffect} onClick={goto_fullstack}  >
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={FullstkIcon} alt="img"/>
                    </div>
                    <h3>Fullstack Developmet</h3>
                    <p>frontend and backend technologies, enabling developers to create complete and well-rounded web applications</p>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </section>
    )

}


export default ServicesOverview;