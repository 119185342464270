// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";

export const Bnr = ({ imgsrc, text, subtext }) => {
  return (
    <section
      style={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${imgsrc})`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Col style={{ width: "100vw", backgroundColor: "#0000009f" }}>
        <Container
          className="d-flex align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <div
            style={{
              textAlign: "center",
              // marginLeft: "5vw",
              zIndex: 999,
              fontWeight: 300,
            }}
          >
            <h1
              className="text-md-center text-start"
              style={{ color: "white" }}
            >
              {text}
            </h1>
            <h4
              className="text-md-center text-start text-white"
              style={{ fontWeight: "200" }}
            >
              {subtext}
            </h4>
          </div>
        </Container>
      </Col>
    </section>
  );
};

{
  /* <Col
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "#0000009f" }}
      > */
}
{
  /* <h1
          style={{
            color: "white",
            marginLeft: "5vw",
            zIndex: 999,
            fontWeight: 400,
          }}
        >
          {text}
        </h1> */
}
{
  /* <Col
        className="d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#0000009f" }}
      >
        <h4 className="text-white ">{subtext} </h4>
      </Col> */
}
{
  /* </Col> */
}
{
  /* <Container className="d-flex align-items-center" style={{height:"100vh",width:"100vw"}}>
      <h1
        className="text-center"
          style={{
            color: "white",
            marginLeft: "5vw",
            zIndex: 999,
            fontWeight: 300,
          }}
        >
          {text}
        </h1>
        <h4 className="text-white text-center" style={{fontWeight:"200"}}>{subtext} </h4>
      </Container> */
}
