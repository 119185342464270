import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import router from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <html>
  //   <head>
  //     <meta charset="UTF-8" />
  //     <meta name="google-site-verification" content="wYz7NLgBaBa8KXISHgNNMnSPI9ZJradAvYnm_JruB1I" />
  //     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //     <meta
  //       name="description"
  //       content="InfinityTechLtd - Your Expert Partner in Embedded System Solutions, Firmware Development, NXP, STM32, ARM Cortex, Embedded Linux, IoT Development, and Full Stack Development."
  //     />
  //     <meta
  //       name="keywords"
  //       content="Embedded System, Embedded Firmware Development, NXP, STM32, ARM Cortex, Embedded Linux, IoT Development, Full Stack Development, Firmware Engineering, IoT Solutions, Embedded Software, ARM Microcontrollers, Software Development, Hardware Integration"
  //     />
  //     <meta name="author" content="InfinityTechLtd" />
  //     <meta name="robots" content="index, follow" />
  //     <meta name="googlebot" content="index, follow" />
  //     <meta
  //       name="og:title"
  //       content="InfinityTechLtd - Embedded System and Firmware Development"
  //     />
  //     <meta
  //       name="og:description"
  //       content="Explore expert embedded system solutions, firmware development, and full-stack services with InfinityTechLtd. We specialize in NXP, STM32, ARM Cortex, Embedded Linux, and IoT development."
  //     />
  //     <meta name="og:image" content="your-logo-url.png" />
  //     <meta name="og:url" content="http://www.infinitytech.ltd" />
  //     <meta name="og:type" content="website" />
  //     <title>
  //       InfinityTechLtd - Embedded System Solutions and Product Development
  //     </title>
  //   </head>
  //   <body>
  {/* </body>
</html> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
