
import { MyNavigationBar } from '../components/MyNavbar';
import { Banner } from '../components/SlideShowBanner';
import { HomeBody } from '../components/HomeBody';
import { MyFooter } from '../components/Footer';
import React, { useState } from "react";

import 'aos/dist/aos.css';
import AOS from 'aos';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import loctions from '../assets/imgs/loction.png';





const apiUrl = 'https://api.emailjs.com/api/v1.0/email/send';

const requestData = {
    service_id: "service_yo7gka9",
    template_id: "template_x3h4v8i",
    user_id: "EaabCUZPqPfxYfW5K",
    template_params: {
        to_name: "infinity",
        sender_email: "naderhany960@gmail.com",
        from_name: "tamer khaled",
        message: "testing message"
    }
}
const requestOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestData)
};



let aosEffect = "zoom-out-up";

const ContactUs = () => {
    var screenWidth = window.innerWidth;

    const [validated, setValidated] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => emailjs.init("i6-DtzERfEzNVN4fz"), []);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        console.log('First Name:');
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };
    const sndemail = async () => {
        console.log("name", `${firstName} ${lastName}`);
        console.log("email", email);
        console.log("msg", message);

        if (email.length > 0) {
            const templateParams =
            {
                to_name: "infinity",
                sender_email: `${email}`,
                from_name: `${firstName} ${lastName}`,
                message: `${message}`,
            };
            try {
                await emailjs.send('service_ynyziyg', 'template_ei5yme6', templateParams);
                console.log("email sent successfully.....");
            } catch (error) {
                console.log(error);
            } finally {

            }
        }
        setEmail("");
        setFirstName("");
        setLastName("");
        setMessage("");
        handleShow();
    }


    //onSubmit={handleSubmit}
    return (
        <>


            <MyNavigationBar page=""/>

            <Container style={{ marginTop: "20vh" }} >
                <h1 className="ContentH1" >Contact us </h1>
                <Form noValidate validated={validated} onChange={handleSubmit} >
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01" >
                            <Form.Label style={{ color: "#DF0A85" }}>First name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="First name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}

                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label style={{ color: "#DF0A85" }}>Last name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row >
                        <Form.Group as={Col} controlId="validationCustomUsername">
                            <Form.Label style={{ color: "#DF0A85" }}>Email</Form.Label>
                            <InputGroup hasValidation style={{ width: `(${screenWidth}>700)?"70%":"100%"` }}>
                                <InputGroup.Text id="inputGroupPrepend"  >@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="user@example.com"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}

                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <br></br>
                    <Row >
                        <Form.Group as={Col} controlId="validationCustomUsername">
                            <Form.Label style={{ color: "#DF0A85" }}>Message</Form.Label>
                            <InputGroup style={{ width: `(${screenWidth}>700)?"70%":"100%"` }}  >
                                <InputGroup.Text>Message</InputGroup.Text>
                                <Form.Control
                                    as="textarea"
                                    aria-label="message"
                                    placeholder="leave your message here"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <br></br>   <br></br>
                    <Form.Group className="mb-3">

                    </Form.Group>
                    <Button type="button" size="lg" variant="outline-light" onClick={sndemail} >Send</Button>
                </Form>
            </Container>
            <br></br><br></br><br></br>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={loctions} width={(screenWidth > 700) ? "70%" : "100%"} ></img>
            </div>
            <br></br><br></br><br></br>
            <MyFooter />
            <Modal  style={{background:"#000",color:"#000",marginTop:"300px"}} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>Message sent successfuly</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal >

        </>
    )

}


export default ContactUs;