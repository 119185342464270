import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";
import Image from "react-bootstrap/Image";

import bannerback from "../../assets/imgs/solutions/iot/backgnd.webp";
import i1 from "../../assets/imgs/solutions/iot/ik1.webp";
import i2 from "../../assets/imgs/solutions/iot/ik2.webp";
import i3 from "../../assets/imgs/solutions/iot/ik3.webp";
import i4 from "../../assets/imgs/solutions/iot/ik4.png";
import i5 from "../../assets/imgs/solutions/iot/ik5.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

let aosEffect = "zoom-up";

const body = () => {
  return (
    <>
      <Container className="mt-5" data-aos="fade-in">
        <HeadLine_colored text="Industrial IoT Services" />
        <ContentText_white
          text="Whether you’re an OEM or industrial company, choose infinity
                tech to help improve efficiency on your factory floor and take
                your business to the next level with the help of IoT. With
                infinity tech and IIoT solutions, you can add smart capabilities
                to analog equipment, automate manufacturing processes, and
                increase production levels. Our IIoT company is here to help you
                through complex business change, build a working business
                strategy, and aid organizational transformation.
                "
        />
      </Container>

      <Container className="mt-5 d-flex justify-content-center">
        <Image
          src={i1}
          fluid
          style={{ maxWidth: "100%", borderRadius: "20px" }}
          alt="iot img"
        />
      </Container>

      <br></br>
      <br></br>

      <Right_1
        head="Smart Home Solutions"
        content="infinity Tech ltd creates BSP firmware featuring applications,
      libraries, and device drivers. Our firmware allows a Linux or
      real-time operating system (RTOS) to function on a custom
      device. We also provide board bring-up services to prepare
      gadgets and hardware components for mass production."
        imgsrc={i2}
      />

      <Container>
        <HeadLine_colored text="Wearable Technology Development" />
        <ContentText_white
          text="
            We will help you design a custom wearable device, support its
            business logic with a scalable back end, and pair the solution with
            a mobile app to enable convenient user-to-device interactions.
            Softeq will also interface your wearable gadget with 3rd-party
            devices and applications — in the workplace or in a Smart Home.
            "
        />
      </Container>
      <br></br>
      <br></br>
      <img
        src={i3}
        alt="iot img"
        width="100%"
        height="50%"
        data-aos={aosEffect}
      ></img>
      <br></br>
      <br></br>
      <Container data-aos={aosEffect}>
        <Row>
          <HeadLine_colored text="IoT Dashboards" />
          <ContentText_white
            text="
              With the help of infinity tech ltd, you can configure IoT devices,
              manage cyber-physical systems onsite and remotely, and analyze
              sensor data using BI tools and present it via interactive
              dashboards. Our portfolio includes:"
          />
        </Row>
        <Container>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Web-based HMIs.</li>
              <li>Preventative and predictive maintenance systems.</li>
              <li>
                Custom IoT platforms based on AWS IoT Greengrass, Azure IoT Hub,
                and Google Cloud IoT Core.
              </li>
              <li>Real-time sensor data visualization solutions.</li>
              <li>Admin dashboards for consumer electronics.</li>
            </ul>
          </Col>
        </Container>
      </Container>
      <br></br>
      <br></br>
      <img
        src={i4}
        alt="iot img"
        width="100%"
        height="50%"
        data-aos={aosEffect}
      ></img>
      <br></br>
      <br></br>
      <Container>
        <Row>
          <HeadLine_colored text="Mobile Apps for Connected Devices" />
          <ContentText_white
            text="
              As part of our IoT app development services, we design native and
              cross-platform mobile apps that give you on-the-go access to data
              generated by smart devices and act as remote controls for IoT
              solutions:"
          />
        </Row>
        <Col>
          <ul
            className="text-white fs-4"
            style={{ width: "100%", fontWeight: "200" }}
          >
            <li>Indoor navigation and geofencing apps.</li>
            <li>Mobile apps for wearables.</li>
            <li>Contactless payment solutions.</li>
            <li>Real-time sensor data visualization solutions.</li>
            <li>Image recognition apps.</li>
          </ul>
        </Col>
      </Container>
      <br></br>
      <br></br>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={i5}
          alt="iot img"
          width="30%"
          height="30%"
          data-aos={aosEffect}
        ></img>
      </div>
    </>
  );
};

const Iot = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      <MyNavigationBar data-aos={aosEffect} page="Solutions" />
      <Bnr
        imgsrc={bannerback}
        text="IoT Development Services"
        subtext=" As an innovative startup or enterprise, shifting tech responsibility to an experienced tech partner is a smart choice. With our IoT development services, infinity is an experienced tech partner you can rely on. We can help you build smart gadgets, make analog products digital, and create infrastructure for custom IoT solutions. If you plan to undergo a holistic business change, our IoT consulting strategists can guide you through the process seamlessly."
      ></Bnr>

      {body()}
      <MyFooter />
    </section>
  );
};

export default Iot;
