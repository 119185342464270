import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { ContentText_white, HeadLine_colored } from "./texts";

export const Right_1 = ({ head, content, imgsrc, scale }) => {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      data-aos="fade-left"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-md-flex justify-content-end align-items-center">
          <div>
            <HeadLine_colored text={head} />
            <ContentText_white text={content} />
          </div>
        </Col>

        <Col className="d-flex justify-content-center align-items-center">
          <Image
            src={imgsrc}
            style={{
              maxWidth: scale != undefined ? `${scale}%` : "60%",
              borderRadius: "15px",
            }}
            alt="iot img"
          />
        </Col>
      </Row>
    </Container>
  );
};

export const Left_1 = ({ head, content, imgsrc, scale }) => {
  return (
    <Container
      className="my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      data-aos="fade-right"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-md-flex d-none justify-content-center align-items-center">
          <Image
            src={imgsrc}
            fluid
            style={{
              maxWidth: scale != undefined ? `${scale}%` : "60%",
              borderRadius: "15px",
            }}
            alt="iot img"
          />
        </Col>

        <Col className="d-md-flex d-block justify-content-end align-items-center">
          <div>
            <HeadLine_colored text={head} />
            <ContentText_white text={content} />
          </div>
        </Col>

        <Col className="d-md-none d-flex justify-content-center align-items-center">
          <Image
            src={imgsrc}
            fluid
            style={{
              maxWidth: scale != undefined ? `${scale}%` : "60%",
              borderRadius: "15px",
            }}
            alt="iot img"
          />
        </Col>
      </Row>
    </Container>
  );
};

{
  /* <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
      IOT Solutions
    </h2>
    <ul className="text-white fs-3" style={{ width: "100%" }}>
      <li>wearable Gadgets</li>
      <li>Back-end infrastructure</li>
      <li>iot apps and software</li>
      <li>converting analog to digital</li>
      <li>industrial iot systems</li>
      <li>data warehouse and analytics</li>
      </ul> */
}

{
  /* <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
        Embedded Software
      </h2>
      <ul className="text-white fs-3" style={{ width: "100%" }}>
        <li>Firmware Development </li>
        <li>Middleware and custom drivers</li>
        <li>Bluetooth apps</li>
        <li>Human Machine Interface development</li>
        <li>Smart home Systems</li>
        <li>Sensors solution</li>
      </ul> */
}
