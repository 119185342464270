import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { MyNavigationBar } from '../../components/MyNavbar';
import AOS from 'aos';
import 'aos/dist/aos.css';

import ik1 from '../../assets/imgs/solutions/ikons/ik1.png'
import ik2 from '../../assets/imgs/solutions/ikons/ik2.png'
import ik3 from '../../assets/imgs/solutions/ikons/ik3.png'
import ik4 from '../../assets/imgs/solutions/ikons/ik4.png'


let aosEffect = "zoom-out-up";

const SolutionsOverview = () => {
    const nav = useNavigate();
    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: "ease-in-sine",
            delay: 50,
        });

        const interval = setInterval(() => {
            AOS.refresh();
        }, 100);

        return () => clearInterval(interval);

    }, []);

    const goto_iot = () => {
        window.scrollTo(0, 0);
        nav("/solutions/iot")
    };
    const goto_ai_ml = () => {
        window.scrollTo(0, 0);
        nav("/solutions/aiml")
    };
    const goto_cv = () => {
        window.scrollTo(0, 0);
        nav("/solutions/cv")
    };
    const goto_automation = () => {
        window.scrollTo(0, 0);
        nav("/solutions/automation")
    };


    return (
        <section className="servicesPage" data-aos={aosEffect}>
            <MyNavigationBar page='Solutions'/>
            <br></br><br></br><br></br>
            <h1 className="serviceHeader">our services</h1>
            <div className="serviceGrid">

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_iot}>
                    <div className='serviceCardLogo' >
                        <img width={100} height={100} src={ik1} alt="img" />
                    </div>
                    <h3>Internet Of Things</h3>
                    <p>
                        network of interconnected devices, objects, and sensors that can communicate and exchange data over the internet, enabling them to collect, share, and act upon information.
                    </p>
                </div>

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_ai_ml}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={ik2} alt="img" />
                    </div>
                    <h3>Ai and Machine Learning</h3>
                    <p>
                        focuses on creating systems capable of performing tasks that typically require human intelligence, such as problem-solving, pattern recognition, and decision-making.
                    </p>
                </div>

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_cv}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={ik3} alt="img" />
                    </div>
                    <h3>Computer Vision</h3>
                    <p>
                        empowers computers to interpret and understand visual information from the world, including images and videos.
                    </p>
                </div>

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_automation}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={ik4} alt="img" />
                    </div>
                    <h3>Industerial Automation</h3>
                    <p>
                        the use of advanced technologies such as robotics, sensors, and control systems to streamline and optimize industrial processes, reducing manual intervention and increasing efficiency.
                    </p>
                </div>

            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </section>
    )

}


export default SolutionsOverview;