import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

import bannerback from "../../assets/imgs/solutions/automation/backgnd.webp";
import i1 from "../../assets/imgs/solutions/automation/ik1.webp";
import i2 from "../../assets/imgs/solutions/automation/ik2.webp";
import i3 from "../../assets/imgs/solutions/automation/ik3.webp";
import i4 from "../../assets/imgs/solutions/automation/ik4.webp";

let aosEffect = "zoom-up";

const body = () => {
  return (
    <>
      <br></br>
      <br></br>

      <Container data-aos="fade-in">
        <Row>
          <Col>
            <HeadLine_colored text="Factory Automation" />
            <ContentText_white
              text="infinity equips manufacturing companies with Smart Factory
                solutions designed to revamp assembly lines, streamline batch
                production, reduce waste, and help human operators manage
                connected machines in a convenient manner:"
            />
          </Col>
          <Col className="d-flex justify-content-center">
            <img
              src={i1}
              style={{
                borderRadius: "15px",
                marginTop: "10%",
                maxWidth: "70vw",
              }}
              alt="iot img"
              className="d-flex justify-content-center"
            ></img>
          </Col>
        </Row>
        <br></br>
        <br></br>

        <Container data-aos={aosEffect}>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>
                Industrial Control Systems (ICSs)Supervisory Control and Data
                Acquisition (SCADA).
              </li>
              <li>Computer Numerical Controls (CNCs).</li>
              <li>Human Machine interface (HMI).</li>
            </ul>
          </Col>
        </Container>
      </Container>

      <Right_1
        head="Quality Control"
        content="We craft software systems that enable industrial companies to
                track and document materials processing, plan resources within
                an organization, reduce the cost of quality, and meet
                industry-specific standards:"
        imgsrc={i2}
        scale={501}
      />
      <Container>
        <ul
          className="text-white fs-4 "
          style={{ width: "100%", fontWeight: "200" }}
        >
          <li>Manufacturing Execution Systems (MESs).</li>
          <li>Overall Equipment Effectiveness (OEE) systems.</li>
          <li>Quality Management Software (QMS).</li>
        </ul>
      </Container>

      <Left_1
        head="Industrial Robotics"
        content="infinity develops embedded software, drivers, and smart controls
      for industrial robots that take over manufacturing tasks
      (assembly, welding, palletizing, material handling, etc.),
      monitor plant infrastructure, and shorten production cycles:"
        imgsrc={i3}
      />
      <Container>
        <Row>
          <Col className="d-md-block d-none"></Col>
          <Col>
            <ul
              className="text-white fs-4 "
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Robotic arm solutions.</li>
              <li>Collaborative robots (cobots).</li>
              <li>Inspection drones.</li>
            </ul>
          </Col>
        </Row>
      </Container>

      <Right_1
        head="Predictive Maintenance"
        content="infinity helps industrial companies anticipate machinery
      failure, prevent accidents, and streamline the replenishment of
      spare parts. This is achieved through the continuous analysis of
      equipment condition data backed up by our expertise in:"
        imgsrc={i4}
      />
      <Container>
        <ul
          className="text-white fs-4 "
          style={{ width: "100%", fontWeight: "200" }}
        >
          <li>Sensor data acquisition and storage.</li>
          <li>Deep Learning.</li>
          <li>Equipment health monitoring.</li>
          <li>Alert notification system design.</li>
          <li>Maintenance decision support system development.</li>
        </ul>
      </Container>

      <br></br>
      <br></br>
      <br></br>
    </>
  );
};
const Automation = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      <MyNavigationBar data-aos={aosEffect} page="Solutions" />
      <Bnr
        imgsrc={bannerback}
        text="Industrial Automation and Robotics Solutions"
        subtext="infinity provides industrial automation services to manufacturing,
                    logistics, and warehouse companies that are currently undergoing
                    Digital Transformation. We help them build robotics and industrial
                    automation systems that digitalize workflows, improve material and
                    asset tracking, and increase production output."
      ></Bnr>

      {body()}
      <MyFooter />
    </section>
  );
};

export default Automation;
