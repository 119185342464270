import { InfinitySpin } from "react-loader-spinner";

const Loading = () => {
    return (
        <body>
            <div
                style={{ backgroundColor: "#000000" }}
                className="loading position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
            >
                <InfinitySpin width="200" color="#0398fc" />
            </div>
        </body>
    );
};

export default Loading;