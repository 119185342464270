
import { useNavigate } from "react-router-dom";
import React, { useEffect, useCallback, useState } from 'react';
import { MyNavigationBar } from '../components/MyNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MyFooter } from '../components/Footer';
import Carousel from 'react-bootstrap/Carousel';
import { Stack } from "react-bootstrap";

import bannerback from '../assets/imgs/portofolio/background.png';

import p01 from '../assets/imgs/portofolio/101.jpeg';
import p02 from '../assets/imgs/portofolio/102.png';
import p03 from '../assets/imgs/portofolio/103.png';

import p11 from '../assets/imgs/portofolio/201.jpg';
import p12 from '../assets/imgs/portofolio/202.png';
import p13 from '../assets/imgs/portofolio/203.png';

import p21 from '../assets/imgs/portofolio/301.jpg';
import p22 from '../assets/imgs/portofolio/302.png';

import p31 from '../assets/imgs/portofolio/401.jpeg';

import p41 from '../assets/imgs/portofolio/501.jpg';
import p42 from '../assets/imgs/portofolio/502.jpg';
import p43 from '../assets/imgs/portofolio/503.jpg';
import p44 from '../assets/imgs/portofolio/504.jpg';

import i1 from '../assets/imgs/portofolio/ik1.png';
import i2 from '../assets/imgs/portofolio/ik2.png';
import i3 from '../assets/imgs/portofolio/ik3.png';
import i4 from '../assets/imgs/portofolio/ik4.png';


let aosEffect = "zoom-up";

let ix = 0;
let manual = false;
let opcty = 0.5;




const head = () => {

    return (
        <>
            <section style={
                {
                    width: '100%',
                    height: "100vh",
                    backgroundImage: `url(${bannerback})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"

                }} >
                <MyNavigationBar data-aos={aosEffect} page='Portofolio'/>
                <Col data-aos={aosEffect}>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 className="headlineH1">
                        Projects
                    </h1>
                    <br></br>


                </Col>

            </section>
        </>
    )
}

const body = () => {
    var screenWidth = window.innerWidth;
    var screenHgy = window.innerHeight;
    let h1b = screenWidth > 600 ? true : false;


    return (
        <>

            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >ALWS® Advanced Laser Warning System</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}> Confidental</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Custom software.</li>
                                <li>Hardware Design.</li>
                                <li>Mechanical Design.</li>
                                <li>Embedded Firmware.</li>
                                <li>Mass Production Planning.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>


                <Carousel fade >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p01}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p02}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p03}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel>
            </Container>




            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >IoT ALPR Camera System</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}> Confidental</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Custom software.</li>
                                <li>Azure IoT.</li>
                                <li>Mechanical Design.</li>
                                <li>Embedded Firmware.</li>
                                <li>Embedded Linux.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>


                <Carousel fade  >

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p11}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p12}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p13}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel>
            </Container>






            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >Rolled Ankle Treatment</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}> CoETech</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Custom software.</li>
                                <li>Embedded Firmware.</li>
                                <li>Mobile App.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
                <Carousel fade  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p21}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p22}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>



            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >A\C Smart Wall Thermostate</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rheem AUE</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Smart Life | Alexa Skills.</li>
                                <li>Hardware Design.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
                <Carousel fade  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p31}
                            alt="First slide"
                        />
                    </Carousel.Item>

                </Carousel>
            </Container>


            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >Astronomy Station Upgrade</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Confiential</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Industrial Control.</li>
                                <li>Custom software development.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
                <Carousel fade  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p41}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p42}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "35px" }}
                            src={p43}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-"
                            style={{ borderRadius: "35px" }}
                            src={p44}
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>


            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >Bike Radar System</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>CoETEC</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Smart Life | Alexa Skills.</li>
                                <li>Hardware Design.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
                <Carousel fade  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "0px" }}
                            src={i1}
                            alt="First slide"
                        />
                    </Carousel.Item>

                </Carousel>
            </Container>

            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >Energy Master HVAC IoT solution</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}> Fresh Air for conditioning systems</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Smart Life | Alexa Skills.</li>
                                <li>Hardware Design.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
                <Carousel fade  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "0px" }}
                            src={i2}
                            alt="First slide"
                        />
                    </Carousel.Item>

                </Carousel>
            </Container>


            <Container>
                <br></br><br></br>
                <br></br><br></br>
                <h1 className="ContentH1" >Twisty Machine</h1>
                <Container  >
                    <Stack direction="horizontal" gap={3}>
                        <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Client:</h1>
                        <h3 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>CoETech</h3>
                    </Stack>
                    <Container data-aos={aosEffect}>
                        <Row>
                            <h1 style={{ color: "white", fontWeight: "700px", display: "inline-block" }}>Rule:</h1>
                        </Row>
                        <Col>
                            <ul className="ContentUlx">
                                <li>Full Product Development.</li>
                                <li>Smart Life | Alexa Skills.</li>
                                <li>Hardware Design.</li>
                            </ul>
                        </Col>

                    </Container>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
                <Carousel fade  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "0px" }}
                            src={i3}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            style={{ borderRadius: "0px" }}
                            src={i4}
                            alt="First slide"
                        />
                    </Carousel.Item>

                </Carousel>
            </Container>

            <br></br>
            <br></br>
            <br></br>
            <br></br>


        </>
    )
}

const Portofolio = () => {
    const [Sx, setSx] = useState(0);
    const [p3imgs, setp3imgs] = useState(0);

    useEffect(() => {

        const interval = setInterval(() => {
            setSx(prevSx => prevSx + 1);
            if (ix < 2) {
                ix++;
            }
            else {
                ix = 0;
            }
            opcty = 0;


        }, 3000);



        return () => clearInterval(interval);
    }, []);


    const nav = useNavigate();
    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: "ease-in-sine",
            delay: 50,
        });

        const interval = setInterval(() => {
            AOS.refresh();
        }, 100);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);

    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            if (p3imgs < 3) {
                setp3imgs((previous) => {
                    return previous++;
                });
            }
            else {
                setp3imgs(0);
            }
        }, 2000);
    });
    return (
        <section>
            {head()}

            {body()}
            <MyFooter />
        </section>

    )
}

export default Portofolio;