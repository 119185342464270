import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/services/dsktop/backgnd.webp";
import i1 from "../../assets/imgs/services/dsktop/ik1.webp";
import i2 from "../../assets/imgs/services/dsktop/ik2.webp";
import i3 from "../../assets/imgs/services/dsktop/ik3.webp";
import i4 from "../../assets/imgs/services/dsktop/ik4.webp";
import i5 from "../../assets/imgs/services/dsktop/ik5.webp";
import i6 from "../../assets/imgs/services/dsktop/ik6.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

let aosEffect = "zoom-up";

const head = () => {
  return (
    <Bnr
      imgsrc={bannerback}
      text="Desktop Development Services"
      subtext="infinity Tech ltd engineers complex embedded systems that combine hardware and user interfaces with powerful software, connectivity, and proximity technologies. We cover the full embedded systems development cycle: from product strategy, design, and system development to implementing embedded integrations, testing, and user experience."
    ></Bnr>
  );
};
{

}

const body = () => {
  return (
    <>
      <Right_1
        head="System-Level Desktop Apps"
        content="Drivers, system services, and other software that interfaces
      with Operating Systems to connect them with various devices:
      sensors that catch vibration, sound and light movement;
      networking peripherals, displays and more."
        imgsrc={i1}
      />

      <Left_1
        className="mt-5"
        head="3D Modeling / CAD Systems"
        content=" Desktop tools for computer-aided modeling and drafting that
      optimize the processes of construction, help visualize design
      concepts and simulate how a design will perform in the real
      world."
        imgsrc={i2}
      />

      <Right_1
        head="Libraries and SDKs"
        content="Build a new product or augment an existing one with a more
        compelling toolset. Through library development and SDK
        creation, 3rd party software can be adapted to any engineering
        needs."
        imgsrc={i3}
      />

      <Left_1
        className="mt-5"
        head="Plugins"
        content="Software components with unique functionality that extend the
        capabilities of an existing product or a 3rd party platform,
        according to your specific business requirements."
        imgsrc={i4}
      />


      <Right_1
        head="Multimedia Apps"
        content="Apps and systems that record, store, analyse, process, and
        stream image, audio and video content."
        imgsrc={i5}
      />

      <Left_1
        className="mt-5"
        head=" Native and Cross-Platform Desktop Apps"
        content="Native apps for Windows, Mac and Linux. Multi-Platform desktop
        applications built on Qt, .NET (WPF, UWP, Xamarin), and
        Electron.js technologies with a single codebase seamlessly
        running on various operating systems."
        imgsrc={i6}
      />


    </>
  );
};

const Desktop_S = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      <MyNavigationBar data-aos={aosEffect} page="Services" />
      {head()}

      {body()}
      <MyFooter />
    </section>
  );
};

export default Desktop_S;
