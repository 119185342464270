import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";

import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/services/embedd/backgnd.webp";
import i1 from "../../assets/imgs/services/embedd/ikn1.webp";
import i2 from "../../assets/imgs/services/embedd/ikn2.webp";
import i3 from "../../assets/imgs/services/embedd/ikn3.webp";
import i4 from "../../assets/imgs/services/embedd/ikn4.webp";

let aosEffect = "zoom-up";

const body = () => {
  return (
    <>
      <br></br>
      <br></br>

      <Right_1
        head="Linux kernel Development"
        content="Our Linux Kernel developers go down to the OS core to modify the
    Linux layer that accesses hardware. This way, we can extend a
    gadget’s memory, optimize CPU usage, manage peripherals, and use
    custom-built drivers to interface the device with other IT
    infrastructure components."
        imgsrc={i1}
      />
      <br></br>
      <br></br>
      <Left_1
        head="Board Support Package (BSP) Development"
        content="                infinity Tech ltd creates BSP firmware featuring applications,
      libraries, and device drivers. Our firmware allows a Linux or
      real-time operating system (RTOS) to function on a custom
      device. We also provide board bring-up services to prepare
      gadgets and hardware components for mass production."
        imgsrc={i2}
      />
      <br></br>
      <br></br>

      <Right_1
        head="Digital Signal Processing (DSP)"
        content="Our firmware development expertise in DSP spans real-time
    analysis of sensor, audio, and video data. We can augment DSP
    solutions with machine learning algorithms, locally or in the
    cloud. This helps detect objects in a camera feed or remove
    noise from a voice recording. In equipment monitoring, it also
    aids pattern recognition."
        imgsrc={i3}
      />
      <br></br>
      <br></br>

      <Left_1
        head="Bare Metal Firmware for Sensors"
        content="Using bare metal firmware development, we can create a program
      without an operating system. This enables your device to execute
      a task without external control. In this case, our team will
      write an application for a specific use case and insert it
      directly into your hardware. This way, we can teach your devices
      to capture data from sensors. They will then send it to the
      cloud in real time. Or, we can configure specific intervals for
      data transfer."
        imgsrc={i4}
      />
    </>
  );
};

const Embedd = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      <MyNavigationBar data-aos={aosEffect} page="Services" />
      <Bnr
        imgsrc={bannerback}
        text="Embedded Software Development"
        subtext="infinity Tech ltd engineers complex embedded systems that combine hardware and user interfaces with powerful software, connectivity, and proximity technologies. We cover the full embedded systems development cycle: from product strategy, design, and system development to implementing embedded integrations, testing, and user experience."
      ></Bnr>

      {body()}
      <MyFooter />
    </section>
  );
};

export default Embedd;
