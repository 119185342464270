import { useNavigate } from "react-router-dom";
import React, { useEffect, useCallback, useState } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import SimpleImageSlider from "react-simple-image-slider";

import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/industries/autmotive/backgnd.webp";
import i1 from "../../assets/imgs/industries/autmotive/ik1.webp";
import i2 from "../../assets/imgs/industries/autmotive/ik2.webp";
import i3 from "../../assets/imgs/industries/autmotive/ik3.webp";
import i4 from "../../assets/imgs/industries/autmotive/ik4.webp";
import i5 from "../../assets/imgs/industries/autmotive/ik5.webp";
import i6 from "../../assets/imgs/industries/autmotive/ik6.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

let aosEffect = "zoom-up";

let ix = 0;
let opcty = 0.5;
const images = [{ url: i1 }, { url: i2 }, { url: i3 }];

const head = () => {
  return (
    <>
      <MyNavigationBar data-aos={aosEffect} page="Industries" />
      <Bnr
        imgsrc={bannerback}
        text="New Era of Electric and Autonomous Vehicles"
        subtext="Vehicles are totally transforming the driver and passenger
        experience by turning into digital devices. Modern cars require
        advanced HMIs, sophisticated driver assistance systems, and multiple
        communication protocols. Success in the automotive industry lies in
        partnering with companies able to innovate. And infinity is the
        company of choice."
      ></Bnr>
    </>
  );
};

const body = () => {
  var screenWidth = window.innerWidth;
  var screenHgy = window.innerHeight;
  let h1b = screenWidth > 600 ? true : false;

  return (
    <>
      <Container data-aos="fade-in">
        <br></br>
        <br></br>
        <br></br>
        <HeadLine_colored text="What Kind of Customers are you?" />
        <br></br>
        <br></br>
        <Container className="d-md-block d-none">
          <SimpleImageSlider
            style={{ margin: "0 auto" }}
            width={h1b ? "75vw" : "100vw"}
            height={h1b ? "54vw" : "72vw"}
            images={images}
            showNavs={h1b}
            showBullets={true}
          />
        </Container>
      </Container>

      <br></br>
      <br></br>
      <Container>
        <Right_1
          head="ADAS Software and Hardware"
          content="infinity is a machine learning development company with a knack
                    for AI chatbots. We build them for popular messengers and
                    custom-built apps. Our chatbot solutions use AI for deep analysis
                    of conversational patterns. They decode natural language and offer
                    responses based on the context. They can also track user feedback
                    and provide valuable insights."
          imgsrc={i4}
          scale={85}
        />
      </Container>

      <Container data-aos="fade-in">
        <Row>
          <HeadLine_colored text="Our Team Deliver" />
        </Row>
        <Container data-aos={aosEffect}>
          <Col>
            <ul
              className="text-white fs-4 "
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>
                Camera-based vision systems such as intelligent parking
                assistance (IPA), lane change assist (LCA), blind spot detection
                (BSD), rear view camera, and surround view (SV).
              </li>
              <li>
                Sensor solutions based on LiDAR and RADAR technology like
                adaptive cruise control (ACC), autonomous emergency braking
                (AEB), and cross traffic alert (CTA).
              </li>
              <li>E-mirrors for external rear and side view.</li>
            </ul>
          </Col>
        </Container>
      </Container>

      <br></br>
      <br></br>

      <Left_1
        head="Vehicle-to-Everything Systems"
        content="Vehicle-to-everything (V2X) connectivity allows cars to
        communicate with infrastructure, the cloud, and each other.
        Recognition sensors like cameras and radars allow cars to perceive
        their surroundings. Softeq uses a variety of communication
        technologies—DSRC (dedicated short-range communications), Wi-Fi,
        GPS, cellular, Android Auto, and Apple CarPlay—to provide seamless
        V2X connectivity and connect systems to the cloud and mobile
        devices. Our team ensures the smooth connection of electronic
        control units (ECUs)."
        imgsrc={i5}
      />

      {/* <Container data-aos="fade-in">
        <Row>
          <Col>
            <HeadLine_colored text="Vehicle-to-Everything Systems" />
            <ContentText_white
              text="Vehicle-to-everything (V2X) connectivity allows cars to
              communicate with infrastructure, the cloud, and each other.
              Recognition sensors like cameras and radars allow cars to perceive
              their surroundings. Softeq uses a variety of communication
              technologies—DSRC (dedicated short-range communications), Wi-Fi,
              GPS, cellular, Android Auto, and Apple CarPlay—to provide seamless
              V2X connectivity and connect systems to the cloud and mobile
              devices. Our team ensures the smooth connection of electronic
              control units (ECUs)."
            />
          </Col>
          <Col>
            <div
              style={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={i5}
                style={{ borderRadius: "35px" }}
                alt="iot img"
                className="imgClass"
              ></img>
            </div>
          </Col>
        </Row>
      </Container> */}
      <br></br>
      <br></br>
      <Container data-aos={aosEffect}>
        <Row>
          <HeadLine_colored text="Our Team Deliver" />
        </Row>
        <Container>
          <Col>
            <ul
              className="text-white fs-4 "
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>
                Integration of vehicles with navigation systems for traffic
                management..
              </li>
              <li>
                Connection of cars to various types of traffic infrastructure
                like signs, traffic signals, toll booths, work zones, school
                zones, and more.
              </li>
              <li>Relation of vehicles to public transport systems.</li>
              <li>
                Over-the-air (OTA) software updates downloaded autonomously in
                safe mode and designated times.
              </li>
            </ul>
          </Col>
        </Container>
      </Container>

      <br></br>
      <br></br>

      <Right_1
        head="Green Vehicle Technologies"
        content="Building electric vehicles is not enough for automakers to go
    green. OEMs and Tier suppliers need to think about a charger
    network, 100% safety, and better communication between customers
    and their EV. Softeq offers its green car technology services to
    speed up faster adoption and manufacturing of electric vehicles"
        imgsrc={i6}
      />
      {/* <Container data-aos="fade-in">
        <Row>
          <Col>
            <h1 className="ContentH1">Green Vehicle Technologies</h1>
            <p className="ContentUlx">
              Building electric vehicles is not enough for automakers to go
              green. OEMs and Tier suppliers need to think about a charger
              network, 100% safety, and better communication between customers
              and their EV. Softeq offers its green car technology services to
              speed up faster adoption and manufacturing of electric vehicles{" "}
            </p>
          </Col>
          <Col>
            <div
              style={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={i6}
                style={{ borderRadius: "35px" }}
                alt="iot img"
                className="imgClass"
              ></img>
            </div>
          </Col>
        </Row>
      </Container> */}

      <Container>
        <Row>
          <HeadLine_colored text="Our Team Deliver" />
        </Row>
        <Container data-aos={aosEffect}>
          <Col>
            <ul
              className="text-white fs-4 "
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>
                EV communication systems, including car-to-car (C2C) and
                driver-to-car (D2C) telematics.
              </li>
              <li>
                Charging infrastructure – vehicle-to-grid (V2G) technology,
                dynamic load management, smart charging for EVs.
              </li>
              <li>
                Consumer-to-car (C2C) mobile apps that turn down the heating
                system, or turn lights on and off.
              </li>
            </ul>
          </Col>
        </Container>
      </Container>
    </>
  );
};

const Automotive = () => {
  const [Sx, setSx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSx((prevSx) => prevSx + 1);
      if (ix < 2) {
        ix++;
      } else {
        ix = 0;
      }
      opcty = 0;
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      {head()}
      {body()}
      <MyFooter />
    </section>
  );
};

export default Automotive;
