import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import "aos/dist/aos.css";
import AOS from "aos";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Bnr } from "../../components/banner";

import { MyFooter } from "../../components/Footer";
import bannerback from "../../assets/imgs/services/custom/cswdback.webp";
import qticon from "../../assets/imgs/services/custom/qt.webp";
import awsicon from "../../assets/imgs/services/custom/awslgo.webp";
import yoctoicon from "../../assets/imgs/services/custom/yoctokigo.webp";
import bldrooticon from "../../assets/imgs/services/custom/logo-buildroot.webp";

import qtback1 from "../../assets/imgs/services/custom/outrun.webp";
import awsback1 from "../../assets/imgs/services/custom/awsbackgnd.webp";
import yctoBk from "../../assets/imgs/services/custom/yctobackgnd.webp";
import bldrtBk from "../../assets/imgs/services/custom/bldrootbakgnd.webp";

import icon1 from "../../assets/imgs/services/custom/app-development.webp";
import icon2 from "../../assets/imgs/services/custom/prototype.webp";
import icon3 from "../../assets/imgs/services/custom/responsive-design.webp";
import icon4 from "../../assets/imgs/services/custom/toutchGxf.webp";
import icon5 from "../../assets/imgs/services/custom/lvgl.webp";
import { Banner } from "../../components/banner";

let aosEffect = "zoom-up";
let goto_qt;
let goto_aws;
let goto_ycto;
let goto_buildroot;

const head = () => {
  return (
    <Bnr
      imgsrc={bannerback}
      text="Custom Software Development Services"
      subtext="                    "
    />
  );
};

const top_body = () => {
  var screenWidth = window.innerWidth;
  let op = screenWidth > 600 ? 0.7 : 0;
  console.log(op);

  return (
    <section data-aos={aosEffect}>
      <br></br>
      <br></br>
      <br></br>
      <Row className="d-flex justify-content-center p-5">
        <Col className="my-3">
          <div className="d-flex justify-content-center" onClick={goto_qt}>
            <img src={qticon} height="60px" alt="img"></img>
          </div>
          <div className="d-flex justify-content-center">
            <p className="text-white my-3">Qt Development</p>
          </div>
        </Col>
        <Col className="my-3">
          <div className="d-flex justify-content-center" onClick={goto_aws}>
            <img src={awsicon} height="60px" alt="img"></img>
          </div>
          <div className="d-flex justify-content-center">
            <p className="text-white my-3">aws software</p>
          </div>
        </Col>
        <Col className="my-3">
          <div className="d-flex justify-content-center" onClick={goto_ycto}>
            <img src={yoctoicon} height="60px" alt="img"></img>
          </div>
          <div className="d-flex justify-content-center">
            <p className="text-white my-3">yocto development</p>
          </div>
        </Col>
        <Col className="my-3">
          <div
            className="d-flex justify-content-center"
            onClick={goto_buildroot}
          >
            <img src={bldrooticon} height="60px" alt="img"></img>
          </div>
          <div className="d-flex justify-content-center">
            <p className="text-white my-3">buildroot development</p>
          </div>
        </Col>
      </Row>

      {/* <br></br>
      <br></br>
      <br></br>
      <div
        className="d-flex justify-content-center d-md-block d-none "
        style={{
          display: "block",
          backgroundColor: "#D9D9D9",
          opacity: op,
          width: "70%",
          height: "1px",
        }}
      ></div> */}
      <br></br>
      <br></br>
      <br></br>
    </section>
  );
};

const qtSection2 = () => {
  return (
    <>
      {/* className="qtSection" */}
      <Container className=" mb-5">
        <h2 className="fs-1" style={{ color: "#DF0A85", width: "100vw" }}>
          Qt Development
        </h2>
        <h4
          className="text-white "
          style={{ fontWeight: "300", width: "100%" }}
        >
          Qt UI framework helps cross-platform applications perform as if they
          were native. Infinity Tech offers Qt development on embedded devices,
          desktops, and operating systems for GUIs and solutions beyond.
        </h4>
      </Container>
      <img
        src={qtback1}
        alt="iot img"
        width="100%"
        height="60%"
        data-aos={aosEffect}
      ></img>
      <Container
        className="d-flex justift-content-center align-items-center"
        data-aos={aosEffect}
        style={{ width: "100vw" }}
      >
        <Row
          className="d-flex justift-content-center mt-5"
          style={{ width: "100vw" }}
        >
          <Col className="md-5">
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Unbeaten Productivity of Low-Level Qt Development
            </h2>
            <h4
              className="text-white"
              style={{ fontWeight: "300", width: "100%" }}
            >
              Since the Qt UI framework is based on C++, it brings high
              productivity. It has a comprehensive ecosystem of libraries and
              development tools that allow you to develop GUI applications with
              advanced data visualization and 3D object rendering on the fly.
              For example, Qt can be used for:
            </h4>
          </Col>

          <Col className="d-md-block d-none">
            <Image
              src={icon1}
              fluid
              style={{ maxWidth: "60%" }}
              alt="iot img"
            />
          </Col>
        </Row>
        <Col className="d-md-none d-flex justify-content-center ">
          <Image src={icon1} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>
      </Container>
      {/* section 2 */}
      <Container
        data-aos={aosEffect}
        className="d-flex justift-content-center"
        style={{ width: "100vw" }}
      >
        <Col>
          <ul
            className="text-white fs-3"
            style={{ width: "100%", fontWeight: "300" }}
          >
            <li>UI-enabled smart home device ecosystems</li>
            <li>UI-enabled embedded systems</li>
            <li>Streaming systems, walkie-talkies, IPTV</li>
            <li>
              Computer utilities (e.g., calculators, text editors, players)
            </li>
            <li>Messengers</li>
            <li>
              Mobile and desktop clients (e.g., Google Drive, Facebook, Twitter)
            </li>
            <li>Graphic tool plugins</li>
            <li>Linux-driven UI-enabled apps</li>
          </ul>
        </Col>
      </Container>
      <br></br>
      <br></br>
      {/* section 3 */}
      <Container
        data-aos={aosEffect}
        className="d-flex justift-content-center"
        style={{ widows: "100vw" }}
      >
        <Row
          className="d-flex justift-content-center"
          style={{ widows: "100vw" }}
        >
          <Col className="d-md-flex justify-content-center d-none">
            <Image
              src={icon2}
              fluid
              style={{ maxWidth: "60%" }}
              alt="iot img"
            />
          </Col>
          <Col>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Sped Up Prototyping and Deployment
            </h2>
            <h4
              className="text-white"
              style={{ fontWeight: "300", width: "100%" }}
            >
              When used for an embedded device UI, Qt software requires only a
              screen resolution to start development. It provides a ready-made
              lightweight environment that runs on a variety of supported
              hardware. The framework comes with a customizable software stack
              and a special toolchain. Direct device deployment, on-device
              debugging, and profiling speed up the development process,
              reducing time to market.
            </h4>
          </Col>
        </Row>
      </Container>
      <Col className="d-md-none d-flex justify-content-center">
        <Image src={icon2} fluid style={{ maxWidth: "60%" }} alt="iot img" />
      </Col>
      {/* section 4 */}
      <Container className="mt-5" data-aos={aosEffect}>
        <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
          Native UI Experience
        </h2>
        <br />
        <h4
          className="text-white"
          style={{ fontWeight: "300", width: "100%" }}
          data-aos={aosEffect}
        >
          With Qt, your snappy UIs work on any screen and each platform as
          native. This is possible due to its APIs and modular structure.
          Additionally, the Qt Quick tool allows you to build dynamic UIs
          featuring fluid animation, transitions, and special effects.
        </h4>
      </Container>
      <br></br> <br></br>
      <br></br> <br></br>
      <Container
        data-aos={aosEffect}
        className="d-flex justift-content-center align-items-center"
        style={{ widows: "100vw" }}
      >
        <Row
          className="d-flex justift-content-center align-items-center"
          style={{ widows: "100vw" }}
        >
          <Col className="d-md-block d-none">
            <Image
              src={icon3}
              fluid
              style={{ maxWidth: "60%" }}
              alt="iot img"
            />
          </Col>
          <Col>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Scalable IoT Ecosystem UI
            </h2>
            <h4
              className="text-white"
              style={{ fontWeight: "300", width: "100%" }}
              data-aos={aosEffect}
            >
              A Qt UI is perfect for controlling IoT-connected devices. Their
              data stored in the Cloud can be accessed and managed from any
              gadget—a mobile phone, tablet, laptop, or PC. The UI you get is
              scalable and portable for each solution you add to the connected
              ecosystem.
            </h4>
          </Col>
        </Row>
      </Container>
      <Col className="d-md-none d-flex justify-content-center">
        <Image src={icon3} fluid style={{ maxWidth: "60%" }} alt="iot img" />
      </Col>
      {/* section 5 */}
      <Container data-aos={aosEffect} style={{ widows: "100vw" }}>
        <Col>
          <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
            Qt Software Alternatives
          </h2>
        </Col>

        <Col>
          <h4
            className="text-white "
            style={{ fontWeight: "300", width: "100%" }}
            data-aos={aosEffect}
          >
            Some projects don’t need a framework as multi-layered as Qt for
            building UIs. In this case, we suggest two other options:
          </h4>
        </Col>
      </Container>
      <Container className="mt-5" data-aos={aosEffect}>
        <Row>
          <Col>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              TouchGfx
            </h2>
            <h4
              className="text-white "
              style={{ fontWeight: "300", width: "100%" }}
              data-aos={aosEffect}
            >
              a user interface (UI) framework developed by STMicroelectronics, a
              global semiconductor company. TouchGFX is designed to create
              advanced and visually rich graphical user interfaces for embedded
              systems, particularly microcontrollers and microprocessors. It
              aims to provide a seamless and engaging user experience on various
              hardware platforms.
            </h4>
          </Col>
          <Col className="d-md-flex d-none align-items-center">
            <Image
              src={icon4}
              fluid
              style={{ maxWidth: "60%" }}
              alt="iot img"
            />
          </Col>
        </Row>
      </Container>
      <Col className="d-md-none d-flex justify-content-center align-items-center">
        <Image src={icon4} fluid style={{ maxWidth: "60%" }} alt="iot img" />
      </Col>
      <br></br>
      <Container
        className="d-flex justify-content-center align-items-center"
        data-aos={aosEffect}
      >
        <Row>
          <Col>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              LVGL
            </h2>
            <h4
              className="text-white "
              style={{ fontWeight: "300", width: "100%" }}
              data-aos={aosEffect}
            >
              Light and Versatile Graphics Library (LVGL)—a C-based library that
              helps create embedded GUIs. It’s perfect when a UI is all you
              need. LVGL demands a low memory footprint and a simpler license
              method. This makes it more convenient for designing plain UIs
            </h4>
          </Col>
          <Col className="d-md-flex d-none align-items-center">
            <Image
              src={icon5}
              fluid
              style={{ maxWidth: "60%" }}
              alt="iot img"
            />
          </Col>
        </Row>
      </Container>
      <Col className="d-md-none d-flex justify-content-center align-items-center">
        <Image src={icon5} fluid style={{ maxWidth: "60%" }} alt="iot img" />
      </Col>
    </>
  );
};

const awsSection = () => {
  return (
    <>
      <section id="awsDevelopment" className="mt-5" data-aos={aosEffect}>
        <Container>
          <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
            Amazon Web Services
          </h2>
          <h4
            className="text-white "
            style={{ fontWeight: "300", width: "100%" }}
            data-aos={aosEffect}
          >
            Together, infinity tech ltd and AWS help organizations accelerate
            their Digital Transformation and get the most of the high-performing
            Amazon cloud environment. We provide custom cloud software
            development services that enable enterprises and tech innovators to
            future-proof their infrastructure investments and streamline the
            shift to SaaS, PaaS, and IaaS business models.
          </h4>
        </Container>
        <br></br>
        <Container className="d-flex justify-content-center">
          <Image
            src={awsback1}
            fluid
            // style={{ maxWidth: "60%" }}
            alt="iot img"
          />
        </Container>
      </section>

      <Container className="mt-5" data-aos={aosEffect}>
        <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
          Infinity Tech ltd is an AWS Select Consulting Partner
        </h2>
        <h4
          className="text-white "
          style={{ fontWeight: "300", width: "100%" }}
          data-aos={aosEffect}
        >
There is more to Infinity Tech Ltd than just your average Amazon Web Services cloud expert. As an AWS Select partner, we will demonstrate to your company how to take advantage of the full range of cloud resources that are accessible, including the most developed public cloud architecture, 99.9% service level guarantees, and adherence to the greatest number of security requirements.
        </h4>
        <br></br>
        <br></br>
        <h4
          className="text-white "
          style={{ fontWeight: "300", width: "100%" }}
          data-aos={aosEffect}
        >
Our professionals will bring the best experience and expertise to the application development process, improve current and integrate new technologies, close gaps in the digital workflow, and streamline the essential procedures to keep your company competitive and relevant. They have successfully completed a rigorous certification and verification process.{" "}
        </h4>
      </Container>
    </>
  );
};

const yoctoSection = () => {
  return (
    <>
      <section id="yoctoDevelopment" data-aos={aosEffect}>
        <Container className="mt-5" data-aos={aosEffect}>
          <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
            Yocto Project development
          </h2>
          <h4
            className="text-white "
            style={{ fontWeight: "300", width: "100%" }}
            data-aos={aosEffect}
          >
            THE YOCTO PROJECT. IT'S NOT AN EMBEDDED LINUX DISTRIBUTION,IT
            CREATES A CUSTOM ONE FOR YOU.
            <br></br>
            <br></br>
            The Yocto Project (YP) is an open source collaboration project that
            helps developers create custom Linux-based systems regardless of the
            hardware architecture.The project provides a flexible set of tools
            and a space where embedded developers worldwide can share
            technologies, software stacks, configurations, and best practices
            that can be used to create tailored Linux images for embedded and
            IOT devices, or anywhere a customized Linux OS is needed.
          </h4>
        </Container>
        <br></br>

        <Container className="d-flex justify-content-center">
          <Image
            src={yctoBk}
            fluid
            // style={{ maxWidth: "60%" }}
            alt="iot img"
          />
          {/* <img
          src={yctoBk}
          alt="iot img"
          width="80%"
          height="50%"
          data-aos={aosEffect}
        ></img> */}
        </Container>
      </section>
    </>
  );
};

const bldrootSection = () => {
  return (
    <>
      <section
        id="buildrootDevelopment"
        className="qtSection"
        data-aos={aosEffect}
      >
        <Container data-aos={aosEffect}>
          <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
            Buildroot development
          </h2>
          <h4
            className="text-white "
            style={{ fontWeight: "300", width: "100%" }}
            data-aos={aosEffect}
          >
            Buildroot is a simple, efficient and easy-to-use tool to generate
            embedded Linux systems through cross-compilation.
          </h4>
        </Container>
        <br></br>
      </section>
      <img
        src={bldrtBk}
        alt="iot img"
        width="100%"
        height="20%"
        data-aos={aosEffect}
      ></img>
    </>
  );
};

const Services_customSW = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-sine",
      delay: 50,
    });
    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);
    return () => clearInterval(interval);
  }, []);

  goto_qt = () => {
    window.location.href = "#qtDevelopment";
  };
  goto_aws = () => {
    window.location.href = "#awsDevelopment";
  };
  goto_ycto = () => {
    window.location.href = "#yoctoDevelopment";
  };
  goto_buildroot = () => {
    window.location.href = "#buildrootDevelopment";
  };

  return (
    <>
      <MyNavigationBar page="Services" />
      {head()}
      {top_body()}
      {qtSection2()}
      {awsSection()}
      {yoctoSection()}
      {bldrootSection()}
      <br></br>
      <br></br>
      <br></br>
      <MyFooter />
    </>
  );
};

export default Services_customSW;
