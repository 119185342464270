import { lazy, Suspense } from "react";
// import { createHashRouter } from "react-router-dom";

// // loading widget
// import Loading from "./components/loading";

// const SplashScreen = lazy(() => import("./components/Splash"));
// const HomePage = lazy(() => import("./pages/Home"));

// // services
// const ServicesOverview = lazy(() => import('./pages/Services/services'));
// const Embedd = lazy(() => import('./pages/Services/embedded'));
// const Services_customSW = lazy(() => import('./pages/Services/custom-sw'));
// const HardWare_S = lazy(() => import('./pages/Services/hardware'));
// const Mobile_S = lazy(() => import('./pages/Services/mobile'));
// const Desktop_S = lazy(() => import('./pages/Services/desktop'));
// const Fullstack_S = lazy(() => import('./pages/Services/fullstack'));

// // solutions
// const SolutionsOverview = lazy(() => import('./pages/Solutions/solutions'));
// const Iot = lazy(() => import('./pages/Solutions/iot'));
// const AiML = lazy(() => import('./pages/Solutions/aiMl'));
// const ComputerVision = lazy(() => import('./pages/Solutions/computerVision'));
// const Automation = lazy(() => import('./pages/Solutions/automation'));

// // industries
// const IndustiesOverview = lazy(() => import('./pages/industries/industies'));
// const Automotive = lazy(() => import('./pages/industries/automotive'));
// const CElectronics = lazy(() => import('./pages/industries/celectronics'));
// const Medical = lazy(() => import('./pages/industries/medical'));
// const Military = lazy(() => import('./pages/industries/military'));

// // contact us
// const ContactUs = lazy(() => import('./pages/ContactUs'));

// // about us
// const AboutUs = lazy(() => import('./pages/aboutus'));

// // portofolio
// const Portofolio = lazy(() => import('./pages/portofolio'));

import { createHashRouter } from "react-router-dom";
import Loading from "./components/loading";
import SplashScreen from "./components/Splash";
import HomePage from "./pages/Home";
import ServicesOverview from "./pages/Services/services";
import Embedd from "./pages/Services/embedded";
import Services_customSW from "./pages/Services/custom-sw";
import HardWare_S from "./pages/Services/hardware";
import Mobile_S from "./pages/Services/mobile";
import Desktop_S from "./pages/Services/desktop";
import Fullstack_S from "./pages/Services/fullstack";
import SolutionsOverview from "./pages/Solutions/solutions";
import Iot from "./pages/Solutions/iot";
import AiML from "./pages/Solutions/aiMl";
import ComputerVision from "./pages/Solutions/computerVision";
import Automation from "./pages/Solutions/automation";
import IndustiesOverview from "./pages/industries/industies";
import Automotive from "./pages/industries/automotive";
import CElectronics from "./pages/industries/celectronics";
import Medical from "./pages/industries/medical";
import Military from "./pages/industries/military";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/aboutus";
import Portofolio from "./pages/portofolio";

const router = createHashRouter([
  {
    index: true,
    // element: <SplashScreen />,
    element: <HomePage />,
    errorElement: "Page Not Found!!",
  },
//   {
//     path: "/home",
//     element: <HomePage />,
//     //(
//     //     <Suspense fallback={<Loading />}>
//     //         <HomePage />
//     //     </Suspense>
//     // ),
//   },
  {
    path: "/about",
    element: <AboutUs />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <AboutUs />
    //     </Suspense>
    // ),
  },
  {
    path: "/portofolio",
    element: (
      <Suspense fallback={<Loading />}>
        <Portofolio />
      </Suspense>
    ),
  },

  {
    path: "/services",
    element: <ServicesOverview />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <ServicesOverview />
    //     </Suspense>
    // ),
  },
  {
    path: "/services/embedded",
    element: <Embedd />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Embedd />
    //     </Suspense>
    // ),
  },
  {
    path: "/services/customsw",
    element: <Services_customSW />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Services_customSW />
    //     </Suspense>
    // ),
  },
  {
    path: "/services/hardware",
    element: <HardWare_S />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <HardWare_S />
    //     </Suspense>
    // ),
  },
  {
    path: "/services/desktop",
    element: <Desktop_S />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Desktop_S />
    //     </Suspense>
    // ),
  },
  {
    path: "/services/mobile",
    element: <Mobile_S />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Mobile_S />
    //     </Suspense>
    // ),
  },
  {
    path: "/services/fullsatck",
    element: <Fullstack_S />,
    //  (
    //     <Suspense fallback={<Loading />}>
    //         <Fullstack_S />
    //     </Suspense>
    // ),
  },

  {
    path: "/solutions",
    element: <SolutionsOverview />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <SolutionsOverview />
    //     </Suspense>
    // ),
  },
  {
    path: "/solutions/iot",
    element: <Iot />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Iot />
    //     </Suspense>
    // ),
  },
  {
    path: "/solutions/aiml",
    element: <AiML />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <AiML />
    //     </Suspense>
    // ),
  },
  {
    path: "/solutions/cv",
    element: <ComputerVision />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <ComputerVision />
    //     </Suspense>
    // ),
  },
  {
    path: "/solutions/automation",
    element: <Automation />,
    //  (
    //     <Suspense fallback={<Loading />}>
    //         <Automation />
    //     </Suspense>
    // ),
  },

  {
    path: "/industries",
    element: <IndustiesOverview />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <IndustiesOverview />
    //     </Suspense>
    // ),
  },
  {
    path: "/industries/celectronics",
    element: <CElectronics />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <CElectronics />
    //     </Suspense>
    // ),
  },
  {
    path: "/industries/medical",
    element: <Medical />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Medical />
    //     </Suspense>
    // ),
  },
  {
    path: "/industries/automotive",
    element: <Automotive />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Automotive />
    //     </Suspense>
    // ),
  },
  {
    path: "/industries/military",
    element: <Military />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <Military />
    //     </Suspense>
    // ),
  },
  {
    path: "/contactus",
    element: <ContactUs />,
    // (
    //     <Suspense fallback={<Loading />}>
    //         <ContactUs />
    //     </Suspense>
    // ),
  },
]);

export default router;
