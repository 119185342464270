
import { MyNavigationBar } from '../components/MyNavbar';
import { Banner } from '../components/SlideShowBanner';
import { HomeBody } from '../components/HomeBody';
import { MyFooter } from '../components/Footer';
import React from "react";

import 'aos/dist/aos.css';
import AOS from 'aos';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';


let aosEffect = "zoom-out-up";

const HomePage = () => {

  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    // Scroll down 100 pixels when the component mounts
    window.scroll({
      top: 3,
      behavior: 'smooth'
    });
  }, []); 

  return (

    <>
      <MyNavigationBar page="home" />
      <Banner data-aos={aosEffect}/>
      <HomeBody />
      <MyFooter />
    </>



  )
}


export default HomePage;