import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../components/Footer";
import ContactUs from "./ContactUs";

import { Bnr } from "../components/banner";
import { Right_1, Left_1 } from "../components/hci";
import { ContentText_white, HeadLine_colored } from "../components/texts";

import bannerback from "../assets/imgs/aboutus/background.webp";
import i1 from "../assets/imgs/aboutus/ik1.webp";
import i2 from "../assets/imgs/aboutus/ik2.webp";
import i3 from "../assets/imgs/aboutus/ik3.webp";
import i4 from "../assets/imgs/aboutus/ik4.webp";
import i5 from "../assets/imgs/aboutus/ik5.webp";
import i6 from "../assets/imgs/aboutus/ik6.webp";
import i7 from "../assets/imgs/aboutus/ik7.webp";
import i8 from "../assets/imgs/aboutus/ik8.webp";

let aosEffect = "fade-in";

const head = () => {
  return (
    <>
      <MyNavigationBar data-aos={aosEffect} page="About" />
      <Bnr
        imgsrc={bannerback}
        text="About Infinity Technologies Ltd"
        subtext=" Infinity Tech was built from scratch at 2013 to focus on creating new products and solving tough problems in the tech field. We've grown to offer help with new ideas, making businesses better through digital changes. What sets us apart is that we can do all of this globally. Let's begin working together to build a brighter future."
      ></Bnr>
    </>
  );
};

const body = () => {
  var screenWidth = window.innerWidth;
  let h1b = screenWidth > 600 ? 0.7 : 0;
  return (
    <>
      <br></br>
      <br></br>
      <br></br>

      <Container>
        <Row>
          {/* <h1 className="ContentH1">Company Vision</h1>
          <h3 className="ContentUlx">
            Infinity Tech Ltd is a startup consulting, software engineering, and
            R&D company, trusted by innovative customers to address their
            complex technical challenges. We provide the full cycle of
            innovation services.<br></br>
            <br></br>
            1. Technology business consulting and digital transformation.
            <br></br>
            2. Solution and product ideation, acceleration, and prototyping
            <br></br>
            3. Software, firmware, and hardware development and support<br></br>
          </h3> */}
          <HeadLine_colored text="Company Vision" />

          <ContentText_white
            text={`Infinity Tech Ltd is a startup consulting, software engineering, and
                    R&D company, trusted by innovative customers to address their
                    complex technical challenges. We provide the full cycle of
                    innovation services.`}
          />
          <ContentText_white text="1. Technology business consulting and digital transformation." />
          <ContentText_white text="2. Solution and product ideation, acceleration, and prototyping." />
          <ContentText_white text="3. Software, firmware, and hardware development and support." />
        </Row>
        <Container>
          <Col>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "35px",
              }}
            >
              <img
                style={{ borderRadius: "35px" }}
                src={i1}
                alt="iot img"
                width={h1b ? "45%" : "80%"}
                height="50%"
                data-aos={aosEffect}
              ></img>
            </div>
          </Col>
        </Container>
      </Container>

      <Container>
        <Row>
          <HeadLine_colored text="Company Mission" />

          <ContentText_white
            text={`Working towards an improved future for our employees, clients, and
                    communities.`}
          />

          <ContentText_white text="1. We commit to design, develop, and deliver complex smart solutions on time and on budget using a creative and result-oriented approach." />
          <ContentText_white text="2. We are highly innovative and enjoy engineering new and meaningful solutions that make the world better." />
          <ContentText_white text="3. We collaborate as partners, learning and working together as one team. We play nice with others." />
          <ContentText_white text="4. We approach our work based on trust and a win-win mindset." />
        </Row>
        <br></br>
        <br></br>
      </Container>

      <br></br>
      <br></br>
      <Container>
        <HeadLine_colored text="Company Values:" />
      </Container>
      <br></br>
      <br></br>

      <Left_1
        head="Trust"
        content="Trust forms the bedrock of our successful relationships. We
    prioritize honesty, practice transparency with discretion, and
    create a safe space for vulnerability with partners and clients."
        imgsrc={i3}
        scale={90}
      />

      <br></br>
      <br></br>
      <Right_1
        head="Empathy"
        content="We're considerate of people's thoughts, feelings, and
          needs—listening, adapting, and enhancing to foster better
          relationships."
        imgsrc={i4}
      />

      <br></br>
      <br></br>
      <Left_1
        head="Collaboration"
        content="We cultivate teamwork, whether within our team or during client
          projects. We collaboratively establish and uphold rules and
          processes. Taking on a partnership approach, we actively
          participate, offer assistance, brainstorm, and share expertise to
          attain shared objectives."
        imgsrc={i5}
      />

      <br />
      <br />

      <Right_1
        head="Commitment"
        content="We uphold commitments and follow through by contributing talent,
                skills, energy, and genuine dedication. Our hard work is driven by
                a true passion for our tasks. Taking responsibility, we ensure
                completion and aim to surpass expectations."
        imgsrc={i6}
      />

      <br />
      <br />

      <Left_1
        head="Innovation"
        content="We remain perpetual learners, absorbing contemporary tech trends
                to cultivate an environment conducive to creativity. This approach
                empowers us to integrate pertinent expertise into every project.
                Our mindset ventures beyond conventional boundaries, embracing
                experimentation, novel approaches, and tailoring them to distinct
                requirements and objectives."
        imgsrc={i7}
      />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};
const AboutUs = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <MyNavigationBar />
      {head()}

      {body()}
      <ContactUs />
    </div>
  );
};

export default AboutUs;
