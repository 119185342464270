import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import homeGif from "../assets/imgs/home/new/iot.webp";
import mcuGif from "../assets/imgs/home/new/embedded.webp";
import hwGif from "../assets/imgs/home/new/hw.webp";
import webGif from "../assets/imgs/home/new/web.webp";
import mobGif from "../assets/imgs/home/new/mobile.webp";
import cloudGif from "../assets/imgs/home/new/cloud.webp";

import pnasncJPG from "../assets/imgs/home/ik2.png";
import cola from "../assets/imgs/home/ik3.jpeg";
import hp from "../assets/imgs/home/ik4.png";
import intl from "../assets/imgs/home/ik5.png";
import msft from "../assets/imgs/home/ik6.png";
import nvda from "../assets/imgs/home/ik7.jpg";
import lenJPG from "../assets/imgs/home/ik1.png";
import hwwiJPG from "../assets/imgs/home/ik8.png";
import { Container, Stack } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Clients() {
  return (
    <Container>
      <Row>
        {/* <Stack direction='horizontal' gap={5}> */}
        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={lenJPG} width={200} height={100} alt="Lenovo Logo"></img>
        </Col>
        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={pnasncJPG} width={200} height={100}></img>
        </Col>

        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={hwwiJPG} width={200} height={100}></img>
        </Col>
        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={cola} width={200} height={100}></img>
        </Col>

        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={hp} width={100} height={100}></img>
        </Col>
        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={intl} width={200} height={100}></img>
        </Col>

        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={nvda} width={200} height={100}></img>
        </Col>
        <Col style={{ textAlign: "center", padding: "50px" }} data-aos="fade">
          <img src={msft} width={200} height={100}></img>
        </Col>
      </Row>
    </Container>
  );
}

function IOT_Solutions() {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      data-aos="fade-left"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-md-flex justify-content-end align-items-center">
          <div>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              IOT Solutions
            </h2>
            <ul className="text-white fs-3" style={{ width: "100%" }}>
              <li>Wearable Gadgets</li>
              <li>Back-end Infrastructure</li>
              <li>IoT Apps and Software</li>
              <li>Converting Analog to Digital</li>
              <li>Industrial IoT Systems</li>
              <li>Data Warehouse and Analytics</li>
            </ul>
          </div>
        </Col>

        <Col className="d-flex justify-content-center align-items-center">
          <Image src={homeGif} style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>
      </Row>
    </Container>
  );
}

function EmbeddedSolutions() {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      fluid
      data-aos="fade-right"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-md-flex d-none justify-content-center align-items-center">
          <Image src={mcuGif} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>

        <Col className="d-md-flex d-block justify-content-end align-items-center">
          <div>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Embedded Software
            </h2>
            <ul className="text-white fs-3" style={{ width: "100%" }}>
            <li>Firmware Development</li>
              <li>Middleware and Custom Drivers</li>
              <li>Bluetooth Apps</li>
              <li>Human-Machine Interface Development</li>
              <li>Smart Home Systems</li>
              <li>Sensors Solution</li>
            </ul>
          </div>
        </Col>

        <Col className="d-md-none d-flex justify-content-center align-items-center">
          <Image src={mcuGif} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>
      </Row>
    </Container>
  );
}

function HWDesignSolutions() {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      fluid
      data-aos="fade-left"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-flex justify-content-end align-items-center">
          <div>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Hardware Design
            </h2>
            <ul className="text-white fs-3" style={{ width: "100%" }}>
            <li>Electronic Circuit Design</li>
              <li>PCB Simulation</li>
              <li>Electronic System Design</li>
              <li>PCB Layout Development</li>
              <li>FPGA Design</li>
              <li>Enclosure Design</li>

            </ul>
          </div>
        </Col>

        <Col className="d-flex justify-content-center align-items-center">
          <Image src={hwGif} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>
      </Row>
    </Container>
  );
}

function WebSolutions() {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      fluid
      data-aos="fade-right"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-md-flex d-none justify-content-center align-items-center">
          <Image src={webGif} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>

        <Col className="d-md-flex d-block justify-content-end align-items-center">
          <div>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Web App Development
            </h2>
            <ul className="text-white fs-3" style={{ width: "100%" }}>
            <li>Payment Solutions</li>
              <li>Intelligent Bot Apps</li>
              <li>Digital Assistant Management Systems</li>
              <li>Portal Development</li>
              <li>Social Network</li>
              <li>Enterprise Web System</li>
              <li>AI/ML App Development</li>

            </ul>
          </div>
        </Col>

        <Col className="d-md-none d-flex justify-content-center align-items-center">
          <Image src={webGif} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>
      </Row>
    </Container>

  );
}
function MobileSolutions() {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      fluid
      data-aos="fade-left"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-flex justify-content-end align-items-center">
          <div>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Mobile App Development
            </h2>
            <ul className="text-white fs-3" style={{ width: "100%" }}>
            <li>Enterprise Mobile Apps</li>
              <li>Smart Devices and Wearables</li>
              <li>Digital Image Processing</li>
              <li>Uber Link Apps</li>
              <li>Multimedia Distribution</li>
              <li>Mobile Managers</li>
              <li>Social Network</li>

            </ul>
          </div>
        </Col>

        <Col className="d-flex justify-content-center align-items-center">
          <Image src={mobGif} fluid style={{ maxWidth: "60%" }} alt="iot img" />
        </Col>
      </Row>
    </Container>

  );
}
function CloudSolutions() {
  return (
    <Container
      className=" my-3 d-md-flex d-block align-items-center justify-content-center"
      style={{ width: "100%" }}
      fluid
      data-aos="fade-right"
    >
      <Row className="d-md-flex d-block align-items-center justify-content-center">
        <Col className="d-md-flex d-none justify-content-center align-items-center">
          <Image
            src={cloudGif}
            fluid
            style={{ maxWidth: "60%" }}
            alt="iot img"
          />
        </Col>

        <Col className="d-md-flex d-block justify-content-end align-items-center">
          <div>
            <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
              Cloud Application
            </h2>
            <ul className="text-white fs-3" style={{ width: "100%" }}>
            <li>Cloud Application Development</li>
              <li>IoT Cloud-based Solutions</li>
              <li>Cloud Optimization</li>
              <li>Migration to the Cloud</li>
              <li>Cloud Integration</li>

            </ul>
          </div>
        </Col>

        <Col className="d-md-none d-flex justify-content-center align-items-center">
          <Image
            src={cloudGif}
            fluid
            style={{ maxWidth: "60%" }}
            alt="iot img"
          />
        </Col>
      </Row>
    </Container>
  );
}

export const HomeBody = () => {
  useEffect(() => {
    AOS.init({
      duration: 1900,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <section
      style={{ width: "100%", backgroundColor: "#000000" }}
      className=" justify-content-center align-items-center p-md-5 p-0"
    >
      <br></br>
      <br></br>

      <h1
        style={{
          color: "#fff",
          fontWeight: 700,
          textAlign: "center",
          marginTop: "0vh",
        }}
      >
        Full-stack Capabilities
      </h1>
      <br></br>
      <br></br>
      <IOT_Solutions />
      <br></br>
      <br></br>
      <EmbeddedSolutions />
      <br></br>
      <br></br>
      <HWDesignSolutions />
      <br></br>
      <br></br>
      <WebSolutions />
      <br></br>
      <br></br>
      <MobileSolutions />
      <br></br>
      <br></br>
      <CloudSolutions />
      <br></br>
      <br></br>
      <br></br>
      <div data-aos="fade-down">
        <h1 style={{ textAlign: "center", fontWeight: 700, color: "#fff" }}>
          OUR Clients
        </h1>
      </div>
      <br></br>
      <Clients />
    </section>
  );
};
