export const HeadLine_colored = ({ text }) => {
  return (
    <h2 className="fs-1" style={{ color: "#DF0A85", width: "100%" }}>
      {text}
    </h2>
  );
};

export const ContentText_white = ({ text }) => {
  return (
    <h4 className="text-white " style={{ fontWeight: "200", width: "100%" }}>
      {text}
    </h4>
  );
};
