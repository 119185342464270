import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";

import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

import bannerback from "../../assets/imgs/industries/military/backgnd.webp";
import i1 from "../../assets/imgs/industries/military/ik1.webp";
import i2 from "../../assets/imgs/industries/military/ik2.webp";
import i3 from "../../assets/imgs/industries/military/ik3.webp";

let aosEffect = "zoom-up";

const head = () => {
  return (
    <>
      <MyNavigationBar data-aos={aosEffect} page="Industries" />
      <Bnr
        imgsrc={bannerback}
        text="Military Industry"
        subtext="The military industry, also known as the defense industry, encompasses the production and development of military hardware, equipment, and technologies for the armed forces of a nation. It plays a crucial role in national security, deterrence, and the ability to protect a country's interests both domestically and internationally.        "
      ></Bnr>
    </>
  );
};

const body = () => {
  var screenWidth = window.innerWidth;
  let h1b = screenWidth > 600 ? 0.7 : 0;
  return (
    <>
      <br></br>
      <br></br>
      <Right_1
        head="Laser Warning Systems (LWS)"
        content="offers an extensive range of sophisticated warning systems for
        armored fighting vehicles. Easily tailored to meet specific
        requirements, the systems provide personnel with accurate
        warnings to support effective and timely responses to laser and
        radar-guided threats. Providing 360-degree threat coverage, the
        warning systems integrate with a full array of countermeasures."
        imgsrc={i1}
        scale={80}
      />
      <br></br>
      <br></br>
      <Left_1
        head="Fire Control Systems"
        content="infinity tech has developed and supplied generations of Fire
        Control Systems (FCS) for Main Battle Tanks (MBTs), medium
        tanks, light tanks, light armored vehicles and artillery systems
        that have repeatedly met the test of combat."
        imgsrc={i2}
        scale={80}
      />
      <br></br>
      <br></br>
      <Container>
        <Row>
          <HeadLine_colored text="Radar Systems" />

          <ContentText_white
            text={`Radars can be used for various applications on ground, on sea and
                    in space. The applications of Radars are listed below. Controlling
                    the Air Traffic, Ship safety, Sensing the remote places, Military
                    applications, In any application of Radar, the basic principle
                    remains the same. Let us now discuss the principle of radar:`}
          />
        </Row>
      </Container>

      <br></br>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "35px",
        }}
      >
        <img
          style={{ borderRadius: "35px" }}
          src={i3}
          alt="iot img"
          width="75%"
          data-aos={aosEffect}
        ></img>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

const Military = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      {head()}

      {body()}
      <MyFooter />
    </section>
  );
};

export default Military;
