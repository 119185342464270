import bback1 from "../assets/imgs/home/new/cover1.webp";
import back2 from "../assets/imgs/home/new/cover2.webp";
import back3 from "../assets/imgs/home/new/cover3.webp";
import SimpleImageSlider from "react-simple-image-slider";
import React, { useCallback, useState, useEffect } from "react";

import Container from "react-bootstrap/Container";

import Col from "react-bootstrap/Col";

const images = [
  {
    url: bback1,
    headline: "Build It With Us",
    content:
      "Serves as an inviting and empowering headline for a product development company. This succinct phrase conveys a sense of collaboration and partnership, encouraging potential clients or collaborators to join forces with the company in bringing their ideas to life.",
  },
  {
    url: back2,
    headline: "Engineer From A to Z",
    content:
      "The phrase suggests that the company is ready and eager to engage in the development process alongside its clients. It implies a commitment to teamwork, expertise, and shared success. Clients can expect a collaborative approach, where the company actively involves them in the product development journey, turning their visions into tangible, high-quality products.",
  },
  {
    url: back3,
    headline: "Transform Your Business",
    content:
      "Encapsulates a powerful call to action for businesses seeking positive change and growth. This succinct phrase conveys a sense of empowerment, urging businesses to take proactive steps toward transformation and improvement.",
  },
];

export const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideComplete = (index) => {
    setCurrentSlide(index);
  };

  const onCompleteSlide = useCallback((idx, length) => {
    setCurrentSlide(idx);
  }, []);

  // Check scroll position and update slider position
  useEffect(() => {
    const handleScroll = () => {
      const banner = document.getElementById("banner");
      if (banner) {
        const scrollY = window.scrollY;
        const bannerHeight = banner.clientHeight;
        const isFixed =
          scrollY >= banner.offsetTop &&
          scrollY <= banner.offsetTop + bannerHeight;
        banner.style.position = isFixed ? "fixed" : "absolute";
        banner.style.top = 0;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //className="bannerHeadline"
  //className="bannerContent"
  return (
    <>
      <section
        id="banner"
        style={{ width: "100%", height: "100%", zIndex: -2 }}
      >
        <SimpleImageSlider
          width="100%"
          height="100%"
          images={images}
          showBullets={false}
          currentIndex={currentSlide}
          onStartSlide={onCompleteSlide}
          showNavs={false}
          slideDuration={0.8}
          autoPlay={true}
          autoPlayDelay={6.0}
          navStyle={1}
        />
      </section>



      <section style={{backgroundColor: "#0000009f",}}>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{
            height: "110vh",
            width: "100vw",
            
          }}
        >
          <Col className="text-start gap-2">
            <h2 style={{ color: "white", zIndex: 999, fontWeight: 700 }}>
              {images[currentSlide > 0 ? currentSlide - 1 : 0].headline}
            </h2>

            <h4 style={{ color: "white", fontWeight: "lighter", zIndex: 999 }}>
              {images[currentSlide > 0 ? currentSlide - 1 : 0].content}
            </h4>
            <br></br>
            <a
              href="/#/services/embedded"
              className="mt-5"
              style={{
                width: "200px",
                height: "40px",
                alignText: "center",
                zIndex: 999,
                padding: "8px 12px",
                color: "white",
                borderRadius: "15px",
                border: "1px solid #fff",
                textDecoration: "none",
                fontFamily:
                  "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                fontSize: "16px",
                // marginTop: "20px", // Adjusted marginTop for better spacing
                // marginLeft: "2%",
              }}
            >
              Learn More
            </a>
          </Col>
        </Container>
      </section>
    </>
  );
};

// const images = [
//     { url: bback1, headline: 'Build It With Us',content:"Apply now to recieve $125K in cash and development services to boost your early stage startup." },
//     { url: back2, headline: 'Engineer From A to Z' ,content:"complex end to end development with one full-stack team.start from any stage."},
//     { url: back3, headline: 'Transform your Buisness' ,content:"don’t settle for a standalone technology.fix  think omnichannel digital ecosystems."},
// ];

// export const Banner = () => {
//     const [currentSlide, setCurrentSlide] = useState(0);

//     const handleSlideComplete = (index) => {
//         setCurrentSlide(index);
//     };
//     const onCompleteSlide = useCallback((idx, length) => {
//         console.log("index=" + idx)
//         setCurrentSlide(idx);
//     }, []);

//     return (
//         <section  style={{ width: '100%', height: '100vh' }} >
//             <SimpleImageSlider className='banr'
//                 width="100%"
//                 height="100%"
//                 images={images}
//                 showBullets={false}
//                 currentIndex={currentSlide}
//                 onStartSlide={onCompleteSlide}
//                 showNavs={false}
//                 slideDuration={0.8}
//                 autoPlay={true}
//                 autoPlayDelay={6.0}
//                 navStyle={1}

//             >
//             </SimpleImageSlider>
//             <div >
//                 <h1 className='bannerHeadline'>{images[(currentSlide > 0) ? (currentSlide - 1) : 0].headline}</h1>
//                 <h3 className='bannerContent'>{images[(currentSlide > 0) ? (currentSlide - 1) : 0].content}</h3>
//             </div>
//             <a href='#' className='BannerButton'>
//                 Learn More
//             </a>
//         </section>
//     )
// }
