import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { MyNavigationBar } from '../../components/MyNavbar';
import AOS from 'aos';
import 'aos/dist/aos.css';

import autoicon from '../../assets/imgs/industries/icons/automotive.png'
import ceicon from '../../assets/imgs/industries/icons/consumer.png'
import medicon from '../../assets/imgs/industries/icons/syringe.png'
import military from '../../assets/imgs/industries/icons/soldier.png'


let aosEffect = "zoom-out-up";

const IndustiesOverview = () => {
    const nav = useNavigate();
    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: "ease-in-sine",
            delay: 50,
        });

        const interval = setInterval(() => {
            AOS.refresh();
        }, 100);

        return () => clearInterval(interval);

    }, []);

    const goto_auto = () => {
        window.scrollTo(0, 0);
        nav("/industries/automotive")
    };
    const goto_celectronics = () => {
        window.scrollTo(0, 0);
        nav("/industries/celectronics")
    };
    const goto_medical = () => {
        window.scrollTo(0, 0);
        nav("/industries/medical")
    };
    const goto_military = () => {
        window.scrollTo(0, 0);
        nav("/industries/military")
    };

    return (
        <section className="servicesPage" data-aos={aosEffect}>
            <MyNavigationBar page='Industries'/>
            <br></br><br></br><br></br>
            <h1 className="serviceHeader">our industries</h1>
            <div className="serviceGrid">

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_auto}>
                    <div className='serviceCardLogo' >
                        <img width={100} height={100} src={autoicon} alt="img" />
                    </div>
                    <h3>Automotive<br></br><br></br></h3>
                    <p>
                        Vehicles are totally transforming the driver and passenger experience by turning into digital devices. Modern cars require advanced HMIs, sophisticated driver assistance systems, and multiple communication protocols.
                    </p>
                </div>

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_celectronics}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={ceicon} alt="img" />
                    </div>
                    <h3>consumer electronics<br></br><br></br></h3>
                    <p>
                        infinity offers consumer electronics product development services to major brands and startups. We’ll help you add novel features to existing devices or create new gadgets from the ground up.
                    </p>
                </div>

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_medical}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={medicon} alt="img" />
                    </div>
                    <h3>Medical and Health care</h3>
                    <p>
                        We help health facilities overhaul legacy IT systems, automate medical image analysis, and monitor patients’ well-being in and out of the hospital.
                    </p>
                </div>

                <div className="serviceCard" data-aos={aosEffect} onClick={goto_military}>
                    <div className='serviceCardLogo'>
                        <img width={100} height={100} src={military} alt="img" />
                    </div>
                    <h3>Military<br></br><br></br></h3>
                    <p>
                        The military industry, also known as the defense industry, encompasses the production and development of military hardware, equipment, and technologies for the armed forces of a nation.
                    </p>
                </div>



            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </section>
    )

}


export default IndustiesOverview;