import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/services/web/backgnd.webp";
import i1 from "../../assets/imgs/services/mobile/ik1.webp";
import i2 from "../../assets/imgs/services/mobile/ik2.webp";
import i3 from "../../assets/imgs/services/mobile/ik3.webp";
import i4 from "../../assets/imgs/services/mobile/ik4.webp";
import i5 from "../../assets/imgs/services/mobile/ik5.webp";
import i6 from "../../assets/imgs/services/mobile/ik6.webp";
import i7 from "../../assets/imgs/services/mobile/ik7.webp";
import i8 from "../../assets/imgs/services/mobile/ik8.webp";
import i9 from "../../assets/imgs/services/mobile/ik9.webp";
import i10 from "../../assets/imgs/services/mobile/ik10-1.webp";

let aosEffect = "zoom-up";

const body = () => {
  return (
    <>
      <Right_1
        head="Enterprise Web Apps"
        content=" Advanced mobile clients and distributed mobility solutions
    integrated with corporate ecosystems (databases, workflows,
    apps) and external platforms."
        imgsrc={i1}
      />

      <Left_1
        head="Social Network Apps"
        content="Any type of social networking app—from solutions for your
      company’s corporate needs to large-scale community-based
      platforms."
        imgsrc={i2}
      />

      <Right_1
        head="Web Messengers"
        content="Custom corporate messengers tailored to the needs of a specific business. Mass market messengers. AI-powered chatbots."
        imgsrc={i3}
      />
      <Left_1
        head="Payment Integrations"
        content="Solutions for integrating popular and custom-built payment methods within mobile apps."
        imgsrc={i4}
      />
      {/* <section className="qtSection" data-aos={aosEffect}>
        <br></br> <br></br>
        <Container>
          <Row>
            <Col style={{ margin: "0 auto" }}>
              <img
                style={{ borderRadius: "35px" }}
                src={i4}
                alt="iot img"
                width={200}
              ></img>
            </Col>
            <Col>
              <h1 className="ContentH1">Payment Integrations</h1>
              <p className="ContentUlx">
                Solutions for integrating popular and custom-built payment
                methods within mobile apps.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}

      <Right_1
        head="VoIP Apps"
        content="IP telephony apps, video chat and web conferencing solutions. Multi-functional unified portals and messengers for corporate infrastructure."
        imgsrc={i5}
        style={{ marginTop: "10%" }} // You can customize the style if needed
      />
      <Left_1
        head="Geo-Service Apps"
        content="Location and user preferences based apps for logistics, food delivery, healthcare."
        imgsrc={i8}
      />
      {/* <section className="qtSection" data-aos={aosEffect}>
        <br></br> <br></br>
        <Container>
          <Row>
            <Col style={{ margin: "0 auto" }}>
              <img
                style={{ borderRadius: "35px" }}
                src={i8}
                alt="iot img"
                width={200}
              ></img>
            </Col>
            <Col>
              <h1 className="ContentH1">Geo-Service Apps</h1>
              <p className="ContentUlx">
                Location and user preferences based apps for logistics, food
                delivery, healthcare.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}

      <Right_1
        head="Digital Image Processing and Camera Software"
        content="Advanced apps that boost device capabilities including graphics processors, cameras and related hardware. Face recognition apps."
        imgsrc={i9}
        style={{ marginTop: "15%" }} // You can customize the style if needed
      />
      <Left_1
        head="Multimedia Distribution Solutions"
        content="Media players, controllers, and renderers for digital media servers. DLNA certified solutions and mobile SDKs for further development of commercial multimedia apps."
        imgsrc={i10}
      />
      {/* <section className="qtSection" data-aos={aosEffect}>
        <br></br> <br></br>
        <Container>
          <Row>
            <Col style={{ margin: "0 auto" }}>
              <img
                style={{ borderRadius: "35px" }}
                src={i10}
                alt="iot img"
                width={200}
              ></img>
            </Col>
            <Col>
              <h1 className="ContentH1">Multimedia Distribution Solutions</h1>
              <p className="ContentUlx">
                Media players, controllers, and renderers for digital media
                servers. DLNA certified solutions and mobile SDKs for further
                development of commercial multimedia apps.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

const Fullstack_S = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      {/* {head()} */}
      <MyNavigationBar data-aos={aosEffect} page="Services" />
      <Bnr
        imgsrc={bannerback}
        text="Web Development Services"
        subtext="infinity Tech ltd engineers complex embedded systems that combine
        hardware and user interfaces with powerful software, connectivity,
        and proximity technologies. We cover the full embedded systems
        development cycle: from product strategy, design, and system
        development to implementing embedded integrations, testing, and user
        experience."
      ></Bnr>
      {body()}
      <MyFooter />
    </section>
  );
};

export default Fullstack_S;
