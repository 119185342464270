import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";

import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/industries/hlthcare/backgnd.webp";
import i1 from "../../assets/imgs/industries/hlthcare/ik1.webp";
import i2 from "../../assets/imgs/industries/hlthcare/ik2.webp";
import i3 from "../../assets/imgs/industries/hlthcare/ik3.webp";
import i4 from "../../assets/imgs/industries/hlthcare/ik4.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

let aosEffect = "zoom-up";

const head = () => {
  return (
    <>
      <MyNavigationBar data-aos={aosEffect} page="Industries" />
      <Bnr
        imgsrc={bannerback}
        text="Digital Solutions for the Healthcare Industry"
        subtext="We help health facilities overhaul legacy IT systems, automate medical image analysis, and monitor patients’ well-being in and out of the hospital. infinity tech also provides custom healthcare software development services to technology startups working on medical devices — vital signs monitors, insulin pumps, or diagnostic equipment. "
      ></Bnr>
    </>
  );
};

const body = () => {
  var screenWidth = window.innerWidth;
  let h1b = screenWidth > 600 ? 0.7 : 0;
  return (
    <>
      <br></br>
      <br></br>
      {/* <Right_1
      head="Why Go Digital?"
      content="Digital healthcare solutions allow hospitals to reduce paperwork,
      serve more patients without adding staff or beds, and access
      medical data stored in disparate IT systems via a single
      user-friendly interface. These days, patients assume that they
      will use technology to interact with healthcare providers. They
      expect digital health solutions to be modern, convenient, and
      consistent with their experience in highly digitalized industries,
      such as banking and eCommerce."
      imgsrc={i1}
      ></Right_1> */}
      <Container data-aos="zoom-in">
        <Row>
          <Col>
            <HeadLine_colored text="Why Go Digital?" />
            <ContentText_white
              text="Digital healthcare solutions allow hospitals to reduce paperwork,
              serve more patients without adding staff or beds, and access
              medical data stored in disparate IT systems via a single
              user-friendly interface. These days, patients assume that they
              will use technology to interact with healthcare providers. They
              expect digital health solutions to be modern, convenient, and
              consistent with their experience in highly digitalized industries,
              such as banking and eCommerce."
            />
          </Col>
          <Col>
            <div
              style={{
                display: "block",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={i1}
                style={{ minWidth: "100%" }}
                alt="iot img"
                className="imgClass"
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <section data-aos={aosEffect}>
        <Container>
          <Row>
            {/* <h1 className="ContentH1">Remote Patient Monitoring (RPM)</h1>
            <p className="ContentUlx">
              Turn to infinity Tech to design custom RPM or telecare solutions,
              which incorporate wearable devices, teleconferencing tools, AI
              chatbots, and voice interfaces. Your solution will function as
              expected in a variety of conditions, meeting the high standards of
              the IT healthcare industry. We'll make sure it:{" "}
            </p> */}
            <HeadLine_colored text="Remote Patient Monitoring (RPM)" />

            <ContentText_white
              text={`Turn to infinity Tech to design custom RPM or telecare solutions,
                    which incorporate wearable devices, teleconferencing tools, AI
                    chatbots, and voice interfaces. Your solution will function as
                    expected in a variety of conditions, meeting the high standards of
                    the IT healthcare industry. We'll make sure it:`}
            />

            <Col>
              <ul
                className="text-white fs-4 "
                style={{ width: "100%", fontWeight: "200" }}
              >
                <li>
                  Captures high-precision sensor data: temperature, heart rate,
                  blood pressure, glucose, etc.
                </li>
                <li>
                  Transfers the data to a local or cloud-based repository.
                </li>
                <li>
                  Analyzes the information against EHRs to detect anomalies.
                </li>
                <li>
                  Alerts hospital staff or ambulance services in emergency
                  cases.
                </li>
                <li>
                  Provides tools (instant messaging, video calls, virtual
                  assistants) for effective doctor-patient communication.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "35px",
        }}
      >
        <img
          style={{ borderRadius: "35px" }}
          src={i2}
          alt="iot img"
          width="70%"
          height="50%"
          data-aos={aosEffect}
        ></img>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <section data-aos={aosEffect}>
        <Container>
          <Row>
            {/* <h1 className="ContentH1">AI-based Medical Imaging Solutions</h1>
            <p className="ContentUlx">
              Looking to speed up medical image analysis and eliminate
              diagnostic errors? We will help you identify patterns in 2D and 3D
              medical images by integrating Machine Learning into custom
              embedded, mobile, and web applications. To that end, we will:
            </p> */}
            <HeadLine_colored text="AI-based Medical Imaging Solutions" />

            <ContentText_white
              text={`Looking to speed up medical image analysis and eliminate
                    diagnostic errors? We will help you identify patterns in 2D and 3D
                    medical images by integrating Machine Learning into custom
                    embedded, mobile, and web applications. To that end, we will:`}
            />

            <Col>
              <ul
                className="text-white fs-4 "
                style={{ width: "100%", fontWeight: "200" }}
              >
                <li>
                  Annotate the images produced by medical equipment: CT, OCT,
                  MRI, X-ray, ultrasound.
                </li>
                <li>Apply augmentations to expand image datasets.</li>
                <li>
                  Feed the data to a neural network model deployed in the cloud.
                </li>
                <li>Ensure compliance with the DICOM standard.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "35px",
        }}
      >
        <img
          style={{ borderRadius: "15px" }}
          src={i3}
          alt="iot img"
          width="80%"
          height="50%"
          data-aos={aosEffect}
        ></img>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <section data-aos={aosEffect}>
        <Container>
          <Row>
            {/* <h1 className="ContentH1">Workflow Management</h1>
            <p className="ContentUlx">
              We help hospitals become more efficient: admit patients faster,
              automate insurance verification, and keep medical records up to
              date. For this, we create healthcare software solutions linking
              hospital departments and decentralized technology systems:
            </p> */}
            <HeadLine_colored text="Workflow Management" />

            <ContentText_white
              text={`We help hospitals become more efficient: admit patients faster,
                    automate insurance verification, and keep medical records up to
                    date. For this, we create healthcare software solutions linking
                    hospital departments and decentralized technology systems:`}
            />

            <Col>
              <ul
                className="text-white fs-4 "
                style={{ width: "100%", fontWeight: "200" }}
              >
                <li>
                  Patient portals supporting appointment scheduling,
                  prescription refills, live chat with a doctor, and eBilling.
                </li>
                <li>Integrated EMR and EHR solutions.</li>
                <li>Inventory tracking systems: RFID, beacons.</li>
                <li>Population health management (PHM) software.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "35px",
        }}
      >
        <img
          style={{ borderRadius: "25px" }}
          src={i4}
          alt="iot img"
          width="90%"
          height="50%"
          data-aos={aosEffect}
        ></img>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

const Medical = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      {head()}

      {body()}
      <MyFooter />
    </section>
  );
};

export default Medical;
