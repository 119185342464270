import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../assets/imgs/logoWhite.svg"; // Make sure the path to the image is correct
import { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import Col from "react-bootstrap/Row";

let cond = false;

export const MyNavigationBar = ({ page }) => {
  const [activeLink, setActiveLink] = useState(page);
  const [scrolled, setScrolled] = useState(false);
  const [clkd, setClkd] = useState("");
  const [logsz, setLogo] = useState("100px");
  const [wsz, setWsz] = useState(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    const onResize = () => {
      if (window.innerWidth > 600) {
        setLogo("100px");
      } else {
        setLogo("70px");
      }
    };
    const rszeCallBack = () => {
      onResize();
      setWsz(window.innerWidth);
    };
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", rszeCallBack);

    // Initial call to set the logo size based on window width

    rszeCallBack();
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", rszeCallBack);
    };
  }, []);

  const chclr = () => {
    setClkd((prev) => {
      if (prev === "dark") {
        return "";
      }
      return "dark";
    });
  };

  return (
    <Navbar
      expand="lg"
      className={scrolled ? "scrolled" : ""}
      data-bs-theme="dark"
      style={{width:"100vw"}}
    >
      <Container>
        {/* <Navbar.Brand href="/"> */}

        <Navbar.Brand>
          <Container className="d-flex">
            <a href="/" style={{ textDecoration: "none" }}>
              <img src={logo} style={{ width: logsz }} alt="Logo" />
            </a>
            <h5 className="text-white mt-0 mt-md-3">
              infinity<br></br>Technologies
            </h5>
          </Container>
        </Navbar.Brand>

        {/* </Navbar.Brand> */}

        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" >
          <span className="navbar-toggler-icon" ></span>
        </Navbar.Toggle> */}

        {wsz > 1000 ? (
          <>
            <Nav className="ms-auto">
            <Nav.Link
                href="/"
                className={
                  activeLink === "home"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Home
              </Nav.Link>
            <Nav.Link
                href="/#/services"
                className={
                  activeLink === "Services"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="/#/solutions"
                className={
                  activeLink === "Solutions"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Solutions
              </Nav.Link>
              <Nav.Link
                href="/#/industries"
                className={
                  activeLink === "Industries"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Industries
              </Nav.Link>
              <Nav.Link
                href="/#/about"
                className={
                  activeLink === "About" ? "active navbar-link" : "navbar-link"
                }
              >
                About
              </Nav.Link>
              <Nav.Link
                href="/#/portofolio"
                className={
                  activeLink === "Portofolio"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Portofolio
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div>
                <a href="/#/contactus" className="navigationBtn">
                  Contact Us
                </a>
              </div>
            </span>
          </>
        ) : (
          <>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${"lg"}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
              placement="end"
              data-bs-theme="dark"
              style={{ backgroundColor: "#000004" }}
            >
              {/* <Offcanvas.Header closeButton className="my-auto"> */}
                {/* <Offcanvas.Title>infinity technologies </Offcanvas.Title> */}
              {/* </Offcanvas.Header> */}
              {/* <Button width="50px">
                    back
                  </Button> */}
              <Stack
                direction="vertical"
                gap={5}
                style={{ marginTop: "180px" }}
              >
                <Nav.Link
                  href="/#/services"
                  className={
                    activeLink === "Services"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  style={{ textAlign: "center" }}
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  href="/#/solutions"
                  className={
                    activeLink === "Solutions"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  style={{ textAlign: "center" }}
                >
                  Solutions
                </Nav.Link>
                <Nav.Link
                  href="/#/industries"
                  className={
                    activeLink === "Industried"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  style={{ textAlign: "center" }}
                >
                  Industries
                </Nav.Link>
                <Nav.Link
                  href="/#/about"
                  className={
                    activeLink === "About"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  style={{ textAlign: "center" }}
                >
                  about
                </Nav.Link>
                <Nav.Link
                  href="/#/portofolio"
                  className={
                    activeLink === "Portofolio"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  style={{ textAlign: "center" }}
                >
                  Portofolio
                </Nav.Link>
                <div style={{ textAlign: "center" }}>
                  <a
                    href="/#/contactus"
                    className="navigationBtn"
                    style={{ marginLeft: "-10px" }}
                  >
                    Contact Us
                  </a>
                </div>
              </Stack>
            </Navbar.Offcanvas>
          </>
        )}
      </Container>
    </Navbar>
  );
};
