import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";

import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

import bannerback from "../../assets/imgs/industries/celectronics/backgnd.webp";
import i1 from "../../assets/imgs/industries/celectronics/ik1.webp";
import i2 from "../../assets/imgs/industries/celectronics/ik2.webp";
import i3 from "../../assets/imgs/industries/celectronics/ik3.webp";
import i4 from "../../assets/imgs/industries/celectronics/ik4.webp";
import i5 from "../../assets/imgs/industries/celectronics/ik5.webp";

let aosEffect = "zoom-up";

const head = () => {
  return (
    <>
      <MyNavigationBar data-aos={aosEffect} page="Industries" />
      <Bnr
        imgsrc={bannerback}
        text="Consumer Electronics Solutions"
        subtext=" infinity offers consumer electronics product development services to major brands and startups. We’ll help you add novel features to existing devices or create new gadgets from the ground up. For this, we draw on our full-stack expertise in hardware design, embedded systems development, AR/VR, mobile apps, and web solutions."
      ></Bnr>
    </>
  );
};

const body = () => {
  return (
    <>
      <br></br>
      <br></br>
      <Right_1
        head="Extended Reality"
        content="Our Virtual, Augmented, and Mixed Reality team will help you
      create immersive content, fine-tune its delivery to destination
      devices, and enhance your products or services with contextual
      data. We have hands-on experience building:"
        imgsrc={i1}
      />
      <Container>
        <ul
          className="text-white fs-4 "
          style={{ width: "100%", fontWeight: "200" }}
        >
          <li>AR remote assistance solutions.</li>
          <li>Immersive entertainment solutions.</li>
          <li>AR manuals and catalogs.</li>
          <li>VR games.</li>
        </ul>
      </Container>

      <br></br>
      <br></br>

      <Left_1
        head="Image Processing"
        content=" Our team uses Machine Learning to uncover patterns in images and
                    video content. We enable data processing in the cloud and mimic
                    the functionality on the device level so that gadgets can
                    respond faster to user requests. We have worked with:"
        imgsrc={i2}
      />
      <Container>
        <Row>
          <Col className="d-md-block d-none"></Col>
          <Col>
            <ul
              className="text-white fs-4 "
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Printing equipment.</li>
              <li>Smart Home security systems.</li>
              <li>Drones (computer vision).</li>
              <li>Medical imaging solutions.</li>
            </ul>
          </Col>
        </Row>
      </Container>

      <br></br>
      <br></br>
      <Right_1
        head="User Interfaces"
        content="We have practical experience designing user interfaces for
        screen-based and screenless devices. Our domain knowledge spans
        wearable technology, native and cross-platform mobile
        applications, web apps, and desktop software:"
        imgsrc={i3}
        scale={80}
      />
      <Container>
        <ul
          className="text-white fs-4 "
          style={{ width: "100%", fontWeight: "200" }}
        >
          <li>
            Graphic HMIs: IoT dashboards, wearables, automotive interfaces.
          </li>
          <li>Voice Interfaces: Alexa, Google Assistant, custom.</li>
          <li>AR interfaces.</li>
        </ul>
      </Container>

      <br></br>
      <br></br>

      <Left_1
        head="Vital Signs and <br></br>Location Monitoring"
        content="infinity helps industrial companies anticipate machinery
        failure, prevent accidents, and streamline the replenishment of
        spare parts. This is achieved through the continuous analysis of
        equipment condition data backed up by our expertise in:"
        imgsrc={i4}
      />
      <Container>
        <Row>
          <Col className="d-md-block d-none"></Col>
          <Col>
            <ul
              className="text-white fs-4 "
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Sensor data acquisition and storage.</li>
              <li>Deep Learning.</li>
              <li>Equipment health monitoring.</li>
              <li>Alert notification system design.</li>
              <li>Maintenance decision support system development.</li>
            </ul>
          </Col>
        </Row>
      </Container>

      <br></br>
      <br></br>
      <Right_1
        head="Smart Energy Usage"
        content="Modern HVAC systems leverage IoT to optimize energy usage. Softeq
        helps you create green tech solutions for HVAC that harvest
        environmental, motion, and occupancy sensor data to add comfort
        and minimize electricity bills. Partner with Softeq to enhance
        your IoT HVAC system with:"
        imgsrc={i5}
      />
      <Container>
        <ul
          className="text-white fs-4 "
          style={{ width: "100%", fontWeight: "200" }}
        >
          <li>Real-time monitoring solutions.</li>
          <li>Data analysis solutions.</li>
          <li>HVAC remote management.</li>
        </ul>
      </Container>

      <br></br>
      <br></br>
      <br></br>
    </>
  );
};
const CElectronics = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      {head()}

      {body()}
      <MyFooter />
    </section>
  );
};

export default CElectronics;
