import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { MyNavigationBar } from "../../components/MyNavbar";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyFooter } from "../../components/Footer";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import bannerback from "../../assets/imgs/services/hardware/backgnd.webp";
import i1 from "../../assets/imgs/services/hardware/ik1.webp";
import i2 from "../../assets/imgs/services/hardware/ik2.webp";
import i3 from "../../assets/imgs/services/hardware/ik3.webp";
import i4 from "../../assets/imgs/services/hardware/ik4.webp";
import i5 from "../../assets/imgs/services/hardware/ik5.webp";
import { ContentText_white, HeadLine_colored } from "../../components/texts";

import { Bnr } from "../../components/banner";
import { Right_1, Left_1 } from "../../components/hci";

let aosEffect = "zoom-up";

const MyCard = ({ title, info, imgsrc }) => {

  return (
    <Card
      bg="dark"
      key="dark"
      text="white"
      className="mt-md-0 mt-3"
    //   style={{ height: "200px" }}
    >
      <Card.Img
        className="mt-1 ms-5"
        style={{ maxWidth: "80px" }}
        src={imgsrc}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text
        >
          {info}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const body = () => {
  return (
    <>
      <Container className="mt-5">
        <Row>
          <HeadLine_colored text="Digital, analog and mix electronics systems from the ground up." />
          <ContentText_white
            text="To set the project up for success, we start with the basics –
              electronic system and circuit design. This lays the groundwork for
              the next critical implementation stages: PCB design and
              simulation, board sample production, trial production and
              mass-production."
          />
        </Row>
        <Container data-aos={aosEffect}>
          <Col>
            <ul
              className="text-white fs-3"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>System requirements gathering and architecture design.</li>
              <li>Functional system design.</li>
              <li>Process design.</li>
            </ul>
          </Col>
        </Container>
      </Container>

      <Container>
        <img
          src={i1}
          alt="iot img"
          width="100%"
          height="150%"
          data-aos={aosEffect}
        ></img>
      </Container>

      <Container>
        <Row>
          <HeadLine_colored text="What you Get" />
        </Row>
      </Container>

      <Container className="mt-5">
        <Container className="d-flex justify-content-center ">
          <Row>
            <Col>
              <MyCard
                title="Scope and Vision document"
                info="A technical document that matches the client’s idea with the
             most applicable technologies."
                imgsrc={i2}
              />
            </Col>
            <Col>
              <MyCard
                title="PoC Solution"
                info="A solution that highlights possible bottlenecks and provides a
            roadmap for designing the optimal solution architecture."
                imgsrc={i3}
              />
            </Col>
          </Row>

          <br></br>
          <br></br>
          <br></br>
        </Container>

        <br></br>
        <br></br>
        <br></br>
        <Container className="d-flex justify-content-center ">
          <Row>
            <Col>
              <MyCard
                title="Electronics Prototype"
                info="A fully functional hardware prototype for assessing concept
      feasibility, as well as identifying and mitigating
      pre-development risks."
                imgsrc={i4}
              />
            </Col>
            <Col>
              <MyCard
                title="Tender Documents"
                info="A set of documents with all the necessary information to apply
      for a tender — specifications of work to be carried out,
      delivery deadlines, contract provisions, plans and drawings."
                imgsrc={i5}
              />
            </Col>
          </Row>

          <br></br>
          <br></br>
          <br></br>
        </Container>

        <Row></Row>
      </Container>

      <Container className="mt-5">
        <Row>
          {/* <h1 className="ContentH1">Hardware Services We Offer</h1> */}
          <HeadLine_colored text="Hardware Services We Offer" />
        </Row>
      </Container>

      <Container className="mt-5 d-flex justify-content-center">
        <Row className="d-flex justify-content-center">
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Electronic system Design</li>
            </ul>
          </Col>
          <br></br>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>PCB Design and Layout</li>
            </ul>
          </Col>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>PCB Design and Simulation</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>FPGA Design</li>
            </ul>
          </Col>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Enclosure Design</li>
            </ul>
          </Col>
          <Col>
            <ul
              className="text-white fs-4"
              style={{ width: "100%", fontWeight: "200" }}
            >
              <li>Other Solutions</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="d-flex justify-content-center"></Container>
    </>
  );
};

const HardWare_S = () => {
  const nav = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 50,
    });

    const interval = setInterval(() => {
      AOS.refresh();
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <section>
      {/* {head()} */}
      <MyNavigationBar data-aos={aosEffect} page="Services" />
      <Bnr
        imgsrc={bannerback}
        text="Hardware Design"
        subtext="Our hardware design engineers have all the skills to cover your
        hardware needs. If you want to go incrementally, we can design
        electronic hardware, an enclosure, PCB, or offer FPGA design
        services. If you need to put together a full-scale electronic
        device, we will take you from conceptualization to trial
        manufacturing to mass production."
      ></Bnr>
      {body()}
      <MyFooter />
    </section>
  );
};

export default HardWare_S;
